<template>
  <div class="card">
    <div class="header">
      <div class="header__esq">
        <div class="titulo">
          Anti Fraude - PF
          <span
            class="sumario"
            v-if="
              !this.resultado.erro && this.resultado.atributos != null && libera
            "
          >
            <span>(</span>
            <span v-for="(param, index) in sumario" :key="param.Nome">
              {{ param.Nome }} : {{ param.Valor }}
              <span v-if="index != Object.keys(sumario).length - 1">,</span>
            </span>
            <span>)</span>
          </span>
        </div>
        <div class="subtitulo">Alertas e score anti fraude</div>
      </div>
      <div class="header__dir">
        <span class="material-icons icon">policy</span>
      </div>
    </div>
    <div v-if="!this.resultado.erro && libera" class="corpo">
      <div class="corpo__esq">
        <Gauge
          :titulo="scorePotencial.especificacao"
          :valor="scorePotencial.valor"
          :classificacao="scorePotencial.classificacao"
          :rating="scorePotencial.rating"
        />
      </div>
      <div class="corpo__dir">
        <Gauge
          titulo="Score de fraude"
          :valor="fraudScore.valor"
          :rating="fraudScore.rating"
          :classificacao="fraudScore.classificacao"
        />
      </div>
    </div>
    <div v-else-if="resultado.atributos == null" class="aviso-vazio">
      Não existe informação de fraude para o CPF informado
    </div>
    <div v-else-if="!libera">
      <Loading />
    </div>
    <!-- <div v-else class="corpo-nao-encontrado">
      Não existe informação de fraude para o CPF informado
    </div> -->
  </div>
</template>

<script>
import { formataData, formataNome } from "@/helpers/utilitarios";
import { mascaras } from "@/helpers/mascaras";
import Gauge from "@/components/Visuais/Gauge";
import { consultasService } from "@/services";
import Loading from "@/components/Loading";

export default {
  name: "BOXATRPF006",
  props: {
    codigo_agrupador: String,
    campos: Object,
    contexto: Object,
  },
  components: {
    Gauge,
    Loading,
  },
  data() {
    return {
      scorePotencial: {
        classificacao: "",
        especificacao: "",
        rating: "",
        valor: 0,
      },

      fraudScore: {
        especificacao: "",
        rating: "",
        valor: 0,
        classificacao: "",
      },
      erro: false,
      sumario: [],
      resultado: {},
      libera: false,
    };
  },

  async mounted() {
    if (this.contexto.resultados.BOXATRPF006) {
      this.resultado = this.contexto.resultados.BOXATRPF006;
      this.libera = true;
      if (!this.contexto.resultados.BOXATRPF006.erro) {
        this.trataDados(this.contexto.resultados.BOXATRPF006);
      } else {
        this.erro = true;
      }
      this.encontraSumario(this.contexto.resultados.BOXATRPF006);
    } else {
      var objetoConsulta = {};
      this.contexto.inferido
        ? (objetoConsulta["cod_transacao_ciclica_pai"] =
            this.contexto.inferido["cod_transacao"])
        : "";
      this.campos["CPF"]
        ? (objetoConsulta["cpf_cnpj"] = this.campos["CPF"])
        : "";
      this.campos["DATA_REF"]
        ? (objetoConsulta["data_ref"] = this.campos["DATA_REF"])
        : "";
      this.campos["CEP"] ? (objetoConsulta["cep"] = this.campos["CEP"]) : "";
      objetoConsulta["cod_ref"] = "BOXATRPF006";
      objetoConsulta["tipo_pessoa"] = "pessoa_fisica";
      objetoConsulta["modo_resultado"] = "codigo";
      objetoConsulta["cod_agrupador"] = this.codigo_agrupador;
      await consultasService
        .realizaConsulta(objetoConsulta)
        .then((resultado) => {
          this.resultado = resultado;
          this.contexto.resultados["BOXATRPF006"] = { ...resultado };
        });
      this.libera = true;
      if (!this.resultado.erro) {
        this.trataDados(this.resultado);
      } else {
        this.erro = true;
      }
      this.encontraSumario(this.resultado);
    }
  },
  methods: {
    trataDados(resultado) {
      this.scorePotencial.classificacao =
        resultado.atributos.boxcep001_classificacao;
      this.scorePotencial.especificacao =
        resultado.atributos.boxcep001_especificacao;
      this.scorePotencial.rating = resultado.atributos.boxcep001_rating;
      this.scorePotencial.valor = resultado.atributos.boxcep001_score;

      this.fraudScore.especificacao =
        resultado.atributos.swfsvar002_especificacao;
      this.fraudScore.rating = resultado.atributos.swfsvar002_rating;
      this.fraudScore.valor = resultado.atributos.swfsvar002_score;
      this.fraudScore.classificacao =
        resultado.atributos.swfsvar002_classificacao;
    },
    encontraSumario(resultado) {
      const sumario_consulta = resultado.sumario_consulta.parametros;
      if (sumario_consulta.cpf) {
        this.sumario.push({
          Nome: "CPF",
          Valor: mascaras.cpf(sumario_consulta.cpf),
        });
      }
      if (sumario_consulta.cep) {
        this.sumario.push({
          Nome: "CEP",
          Valor: mascaras.cep(sumario_consulta.cep),
        });
      }
    },
  },
};
</script>
<style lang="scss" src="@/styles/components/box_resultados.scss" scoped></style>

<style lang="scss" scoped>
.aviso-vazio {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
}
</style>
