<template>
  <div class="card">
    <div class="header">
      <div class="header__esq">
        <div class="titulo">
          Identifica Endereço
          <span class="sumario" v-if="!this.resultado.erro && libera">
            <span>(</span>
            <span v-for="(param, index) in sumario" :key="param.Nome">
              {{ param.Nome }} : {{ param.Valor }}
              <span v-if="index != Object.keys(sumario).length - 1">,</span>
            </span>
            <span>)</span>
          </span>
        </div>
        <div class="subtitulo">
          Exposição de informações sobre um determinado CEP
        </div>
      </div>
      <div class="header__dir">
        <span class="codigo-agrupador">{{ codigo_agrupador }}</span>
        <div class="data" v-if="!this.resultado.erro && libera">
          <span>{{
            contexto.resultados.BOXATRGEO001.sumario_consulta.data_transacao_brt
              .slice(0, 10)
              .split("-")
              .reverse()
              .join("/")
          }}</span>
          <span>{{
            contexto.resultados.BOXATRGEO001.sumario_consulta.data_transacao_brt.slice(
              11,
              19
            )
          }}</span>
        </div>
        <span class="material-icons icon">place</span>
      </div>
    </div>
    <div
      v-if="!this.resultado.erro && this.resultado.atributos != null && libera"
      class="corpo"
    >
      <div class="corpo__esq">
        <p class="item">
          <span class="dado"
            >{{ resultado.atributos.bk_301_001_logradouro_tipo }}
          </span>
          <span class="dado"
            >{{ resultado.atributos.bk_301_001_logradouro }},
          </span>
          <span class="dado">{{ resultado.atributos.bk_301_001_bairro }} </span>
        </p>
        <p class="item">
          <span class="dado"
            >{{ resultado.atributos.bk_301_001_municipio }} -
          </span>
          <span class="dado"
            >{{ resultado.atributos.bk_301_001_aglomerado_uf }}
          </span>
        </p>
        <p class="item">
          <span class="dado"
            >CEP {{ resultado.atributos.bk_301_001_classe_cep }} -
          </span>
          <span class="dado"
            >{{ resultado.atributos.bk_301_001_precisao_cep }}
          </span>
        </p>
      </div>
      <div class="corpo__dir">
        <p class="item">
          <span class="dado"
            >DDD: {{ resultado.atributos.bk_301_001_cod_ddd }}</span
          >
        </p>
        <p class="item">
          <span class="dado"
            >Área da cidade:
            {{ resultado.atributos.bk_301_001_area_km_2 }} km²</span
          >
        </p>
      </div>
    </div>
    <div v-else-if="resultado.atributos == null" class="aviso-vazio">
      Nenhum resultado encontrado
    </div>
    <div v-else-if="!libera">
      <Loading />
    </div>
    <div v-else-if="resultado.erro != null" class="corpo-nao-encontrado">
      {{ resultado.erro.descricao }}
    </div>
    <div class="corpo-inferior">
      <div
        class="alertas"
        :class="{ 'span-2': historico || produtosNaoConsultados.length == 0 }"
      >
        <div class="titulo">
          Alertas:
          <span class="material-icons"> error_outline </span>
        </div>

        <div class="line" />
        <div
          class="corpo-alertas"
          v-if="!loading_alertas && alertas.length != 0"
        >
          <div
            class="alerta-basic"
            :class="{ alerta: alerta.Recomendado && !historico }"
            v-for="alerta in alertas"
            :key="alerta.Nome"
            @click="consultaAlerta(alerta)"
          >
            <div class="bolinha">
              <div class="bolinha" :class="decideCorIndicador(alerta)"></div>
            </div>
            {{ alerta.Nome }}

            <span
              class="material-icons icon-next-consulta"
              v-if="alerta.Recomendado && !historico && permitido(alerta)"
              :class="decideCorIndicador(alerta)"
              >near_me</span
            >
          </div>
        </div>
        <div
          class="corpo-alertas-loading"
          v-else-if="alertas.length == 0 && !loading_alertas"
        >
          Não encontramos alertas para o CEP informado
        </div>
        <div class="corpo-alertas-loading" v-else>
          <Loading />
        </div>
      </div>
      <div
        class="extras"
        v-if="!historico && produtosNaoConsultados.length != 0"
      >
        <div class="titulo">Consultas Disponíveis:</div>
        <div class="line" />
        <div
          class="produtos"
          v-for="produto in produtosNaoConsultados"
          :key="produto"
          @click="addExtra(produto)"
        >
          <div class="bolinha">
            <div class="bolinha --extra"></div>
          </div>
          {{ listaDeProdutos.GEO[produto] }}
          <span class="material-icons icon-next-consulta --extra-icon"
            >near_me</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mascaras } from "@/helpers/mascaras";
import { consultasService } from "@/services";
import Loading from "@/components/Loading";
import alertasJSON from "@/helpers/alertasGEO.json";
import { alertasHelper } from "@/helpers/alertas.js";
import listaProdutos from "@/helpers/lista_produtos.json";
import { produtosStore } from "@/stores/produtos";
import { mapActions, mapState } from "pinia";

export default {
  name: "BOXATRGEO001",
  props: {
    codigo_agrupador: String,
    campos: Object,
    contexto: Object,
    historico: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Loading,
  },
  data() {
    return {
      sumario: [],
      erro: false,
      resultado: {},
      libera: false,
      loading_alertas: true,
      alertas: [],
    };
  },
  async mounted() {
    this.produtosAusentes(this.contexto.requisitados, "GEO");
    if (this.contexto.resultados.BOXATRGEO001) {
      this.libera = true;
      this.resultado = this.contexto.resultados.BOXATRGEO001;
      if (this.contexto.resultados.BOXATRGEO001.erro) {
        this.erro = true;
      }
      this.encontraSumario(this.contexto.resultados.BOXATRGEO001);
    } else {
      var objetoConsulta = {};
      this.campos["CNPJ"]
        ? (objetoConsulta["cpf_cnpj"] = this.campos["CNPJ"])
        : "";
      this.campos["DATA_REF"]
        ? (objetoConsulta["data_ref"] = this.campos["DATA_REF"])
        : "";
      this.campos["CEP"] ? (objetoConsulta["cep"] = this.campos["CEP"]) : "";
      this.contexto.inferido
        ? (objetoConsulta["cod_transacao_ciclica_pai"] =
            this.contexto.inferido.cod_transacao)
        : "";
      objetoConsulta["cod_ref"] = "BOXATRGEO001";
      objetoConsulta["modo_resultado"] = "codigo";
      objetoConsulta["cod_agrupador"] = this.codigo_agrupador;

      await consultasService
        .realizaConsulta(objetoConsulta)
        .then((resultado) => {
          this.resultado = { ...resultado };
          this.contexto.resultados["BOXATRGEO001"] = { ...resultado };
        });
      this.libera = true;
      if (this.resultado.erro) {
        this.erro = true;
      }
      this.encontraSumario(this.resultado);
    }

    // Faz consulta dos alertas
    if (this.contexto.resultados.BOXWRNGEO001) {
      this.alertas = alertasHelper.trataAlertas(
        this.contexto.resultados.BOXWRNGEO001,
        alertasJSON
      );
      this.loading_alertas = false;
    } else {
      if (this.contexto.inferido) {
        var response = await alertasHelper.consultaAlertas(
          "BOXWRNGEO001",
          this.codigo_agrupador,
          this.campos,
          this.contexto.inferido
        );
      } else {
        var response = await alertasHelper.consultaAlertas(
          "BOXWRNGEO001",
          this.codigo_agrupador,
          this.campos
        );
      }
      this.alertas = alertasHelper.trataAlertas(response, alertasJSON);
      this.contexto.resultados.BOXWRNGEO001 = response;
      this.loading_alertas = false;
    }
  },
  methods: {
    permitido(alerta) {
      if (alerta.Recomendado) {
        var permitido = false;
        this.produtos.forEach((produto) => {
          if (alerta.Recomendado == produto.Box) {
            permitido = true;
          }
        });
      }

      return permitido;
    },
    decideCorIndicador(alerta) {
      switch (alerta.Indicador) {
        case "Positivo":
          return "--positive";
        case "Negativo":
          return "--negative";
        case "Atencao":
          return "--warning";
      }
      return "";
    },
    async consultaAlerta(alerta) {
      if (!alerta.Recomendado || this.historico || !this.permitido(alerta)) {
        return;
      }
      if (await alertasHelper.executaAlerta(alerta, this.contexto)) {
        this.$emit("addRequisitado", alerta.Recomendado, this.contexto.id);
      }
    },

    encontraSumario(resultado) {
      const sumario_consulta = resultado.sumario_consulta.parametros;
      if (sumario_consulta.cep) {
        this.sumario.push({
          Nome: "CEP",
          Valor: mascaras.cep(sumario_consulta.cep),
        });
      }
    },
    async addExtra(produto) {
      if (await alertasHelper.executaExtra(listaProdutos.GEO[produto], 1)) {
        this.$emit("addRequisitado", produto, this.contexto.id);
      }
    },
    ...mapActions(produtosStore, ["produtosAusentes"]),
  },
  computed: {
    ...mapState(produtosStore, {
      produtos: "produtos",
    }),
    listaDeProdutos() {
      return listaProdutos;
    },
    produtosNaoConsultados() {
      return this.produtosAusentes(this.contexto.requisitados, "GEO");
    },
  },
};
</script>
<style lang="scss" src="@/styles/components/box_cabecalho.scss" scoped></style>

<style lang="scss" scoped>
.corpo {
  &__esq {
    gap: 10px;
  }

  &__dir {
    gap: 10px;
  }
}

.aviso-vazio {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
}
</style>
