var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"header__esq"},[_c('div',{staticClass:"titulo"},[_vm._v(" Relações Societárias - PF "),(!this.resultado.erro && _vm.libera)?_c('span',{staticClass:"sumario"},[_c('span',[_vm._v("(")]),_vm._l((_vm.sumario),function(param,index){return _c('span',{key:param.Nome},[_vm._v(" "+_vm._s(param.Nome)+" : "+_vm._s(param.Valor)+" "),(index != Object.keys(_vm.sumario).length - 1)?_c('span',[_vm._v(",")]):_vm._e()])}),_c('span',[_vm._v(")")])],2):_vm._e()]),_c('div',{staticClass:"subtitulo"},[_vm._v("Informações de sociedade da pessoa física")])]),_vm._m(0)]),(
      !this.resultado.erro &&
      this.resultado.atributos != null &&
      _vm.libera &&
      this.resultado.registro_encontrado
    )?_c('div',{staticClass:"corpo"},[_c('div',{staticClass:"corpo__esq"},[_c('div',{staticClass:"sociedade-info"},[_vm._m(1),_c('p',[_vm._v(" Início da atividade atual: "+_vm._s(new Date().getFullYear() - _vm.avg_tempo_anos_data_ref_ativa)+", "+_vm._s(_vm.avg_tempo_anos_data_ref_ativa)+" anos ")])]),_c('div',{staticClass:"sociedade-valor"},[_c('div',[_vm._v(" Quantidade de CNPJ associados ao CPF : "+_vm._s(_vm.qtde_cnpj_distintos)+" ")]),_c('div',{staticClass:"sociedade-valor__capital"},[_vm._m(2),_c('span',{staticClass:"sociedade-valor__quantia"},[_vm._v("R$ "+_vm._s(_vm.avg_capital_social_ativa.toLocaleString("pt-BR")))])])])]),_c('div',{staticClass:"corpo__dir"},[_c('div',{staticClass:"datas"},[_c('div',[_vm._v("Início da atividade ativa associada ao CPF")]),(_vm.min_data_inicio != _vm.max_data_inicio)?_c('div',{staticClass:"data-dupla"},[_c('div',[_vm._v("Data mais antiga")]),_c('div',[_vm._v("Data mais recente")]),_c('div',[_vm._v(_vm._s(_vm.min_data_inicio))]),_c('div',[_vm._v(_vm._s(_vm.max_data_inicio))])]):_c('div',{staticClass:"data-simples"},[_c('div',[_vm._v(_vm._s(_vm.max_data_inicio))])])])])]):(_vm.resultado.atributos == null)?_c('div',{staticClass:"aviso-vazio"},[_vm._v(" Não existe relação societária para o CPF informado. ")]):(!_vm.libera)?_c('div',[_c('Loading')],1):_c('div',{staticClass:"corpo-nao-encontrado"},[_vm._v(" Não existe relação societária para o CPF informado. ")])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header__dir"},[_c('span',{staticClass:"material-icons icon"},[_vm._v("group")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sociedade-info__atividade"},[_vm._v(" Possui sociedade ativa "),_c('span',{staticClass:"material-icons check"},[_vm._v("check_circle")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sociedade-valor__capital__titulo"},[_c('span',{staticClass:"material-icons-outlined money"},[_vm._v("paid")]),_vm._v(" Capital Social Médio: ")])
}]

export { render, staticRenderFns }