<template>
  <div class="card">
    <div class="header">
      <div class="header__esq">
        <div class="titulo">
          SINISTRALIDADE DO ENTORNO
          <span class="sumario" v-if="!this.resultado.erro && libera">
            <span>(</span>
            <span v-for="(param, index) in sumario" :key="param.Nome">
              {{ param.Nome }} : {{ param.Valor }}
              <span v-if="index != Object.keys(sumario).length - 1">,</span>
            </span>
            <span>)</span>
          </span>
        </div>
        <div class="subtitulo"></div>
      </div>
      <div class="header__dir">
        <span class="material-icons icon"></span>
      </div>
    </div>
    <div v-if="!this.resultado.erro && libera" class="corpo">
      <div class="corpo__esq">
        <p>
          Exposição total 6m
          {{ resultado.atributos.bk_105_001_exposicao_total_6_m }}
        </p>
        <p>
          Probabilidade sinistro1 6m:
          {{ resultado.atributos.bk_105_001_prob_sin1_6_m }}
        </p>
        <p>
          Probabilidade sinistro4 6m:
          {{ resultado.atributos.bk_105_001_prob_sin4_6_m }}
        </p>
        <p>
          Probabilidade sinistro9 6m:
          {{ resultado.atributos.bk_105_001_prob_sin9_6_m }}
        </p>
        <p>
          Probabilidade sinistro23 6m:
          {{ resultado.atributos.bk_105_001_prob_sin23_6_m }}
        </p>
        <p>
          Probabilidade sinistro 6m:
          {{ resultado.atributos.bk_105_001_prob_sins_6_m }}
        </p>
      </div>
      <div class="corpo__dir">
        <p>
          Premio medio sinistro cep 6m:
          {{ resultado.atributos.bk_105_001_premio_medio_cep_6_m }}
        </p>

        <p>
          Premio medio sin1 6m:
          {{ resultado.atributos.bk_105_001_premio_medio_sin1_6_m }}
        </p>
        <p>
          Premio medio sin4 6m:
          {{ resultado.atributos.bk_105_001_premio_medio_sin4_6_m }}
        </p>
        <p>
          Premio medio sin9 6m:
          {{ resultado.atributos.bk_105_001_premio_medio_sin9_6_m }}
        </p>
        <p>
          Premio medio sin23 6m:
          {{ resultado.atributos.bk_105_001_premio_medio_sin23_6_m }}
        </p>
      </div>
      <div class="line"></div>
      <p>
        boxauto001 classificacao:
        {{ resultado.atributos.boxauto002_classificacao }}
      </p>
      <p>
        boxauto002 especificação:
        {{ resultado.atributos.boxauto002_especificacao }}
      </p>
      <p>boxauto002 rating: {{ resultado.atributos.boxauto002_rating }}</p>
      <p>boxauto002 score: {{ resultado.atributos.boxauto002_score }}</p>
    </div>
    <div v-else-if="!libera">
      <Loading />
    </div>
    <div v-else class="corpo-nao-encontrado">
      {{ resultado.erro.descricao }}
    </div>
  </div>
</template>

<script>
import { mascaras } from "@/helpers/mascaras";
import { consultasService } from "@/services";
import Loading from "@/components/Loading";

export default {
  name: "BOXATRAUTO002",
  props: {
    codigo_agrupador: String,
    campos: Object,
    contexto: Object,
  },
  components: {
    Loading,
  },
  data() {
    return {
      sumario: [],
      erro: false,
      resultado: {},
      libera: false,
    };
  },
  async mounted() {
    if (this.contexto.resultados.BOXATRAUTO002) {
      this.libera = true;
      this.resultado = this.contexto.resultados.BOXATRAUTO002;
      if (this.contexto.resultados.BOXATRAUTO002.erro) {
        this.erro = true;
      } else {
      }
      this.encontraSumario(this.contexto.resultados.BOXATRAUTO002);
    } else {
      var objetoConsulta = {};
      this.campos["CPF"]
        ? (objetoConsulta["cpf_cnpj"] = this.campos["CPF"])
        : "";
      this.campos["DATA_REF"]
        ? (objetoConsulta["data_ref"] = this.campos["DATA_REF"])
        : "";
      this.campos["CEP"] ? (objetoConsulta["cep"] = this.campos["CEP"]) : "";
      this.contexto.inferido
        ? (objetoConsulta["cod_transacao_ciclica_pai"] =
            this.contexto.inferido["cod_transacao"])
        : "";
      objetoConsulta["cod_ref"] = "BOXATRAUTO002";
      objetoConsulta["modo_resultado"] = "codigo";
      objetoConsulta["cod_agrupador"] = this.codigo_agrupador;

      await consultasService
        .realizaConsulta(objetoConsulta)
        .then((resultado) => {
          this.resultado = { ...resultado };
          this.contexto.resultados["BOXATRAUTO002"] = { ...resultado };
        });
      this.libera = true;
      if (this.resultado.erro) {
        this.erro = true;
      }
      this.encontraSumario(this.resultado);
    }
  },
  methods: {
    encontraSumario(resultado) {
      const sumario_consulta = resultado.sumario_consulta.parametros;
      if (sumario_consulta.cep) {
        this.sumario.push({
          Nome: "CEP",
          Valor: mascaras.cep(sumario_consulta.cep),
        });
      }
    },
  },
};
</script>
<style lang="scss" src="@/styles/components/box_resultados.scss" scoped></style>

<style lang="scss" scoped></style>
