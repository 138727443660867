<template>
  <div class="card">
    <div class="header">
      <div class="header__esq">
        <div class="titulo">
          Conjuntura Social - PF
          <span class="sumario" v-if="!this.resultado.erro && libera">
            <span>(</span>
            <span v-for="(param, index) in sumario" :key="param.Nome">
              {{ param.Nome }} : {{ param.Valor }}
              <span v-if="index != Object.keys(sumario).length - 1">,</span>
            </span>
            <span>)</span>
          </span>
        </div>
        <div class="subtitulo">Informações de conjuntura social</div>
      </div>
      <div class="header__dir">
        <span class="material-icons icon">real_estate_agent</span>
      </div>
    </div>
    <div
      v-if="!this.resultado.erro && this.resultado.atributos != null && libera"
      class="corpo"
    >
      <div class="corpo__esq">
        <Gauge
          :titulo="scorePropensity.especificacao"
          :valor="scorePropensity.valor"
          :rating="scorePropensity.rating"
          :classificacao="scorePropensity.classificacao"
        />
      </div>
      <div class="corpo__dir">
        <Gauge
          :titulo="scoreAplication.especificacao"
          :valor="scoreAplication.valor"
          :rating="scoreAplication.rating"
          :classificacao="scoreAplication.classificacao"
        />
      </div>
    </div>
    <div v-else-if="resultado.atributos == null" class="aviso-vazio">
      Nenhum resultado encontrado
    </div>
    <div v-else-if="!libera">
      <Loading />
    </div>
    <div v-else class="corpo-nao-encontrado">
      Não existe informação de Conjuntura Social para o CPF informado
    </div>
  </div>
</template>

<script>
import { formataData, formataNome } from "@/helpers/utilitarios";
import { mascaras } from "@/helpers/mascaras";
import Gauge from "@/components/Visuais/Gauge";
import { consultasService } from "@/services";
import Loading from "@/components/Loading";

export default {
  name: "BOXATRPF005",
  props: {
    codigo_agrupador: String,
    campos: Object,
    resultadoPrevio: Object,
    contexto: Object,
  },
  components: {
    Gauge,
    Loading,
  },
  data() {
    return {
      scorePropensity: {
        especificacao: "",
        rating: "",
        valor: 0,
        classificacao: "",
      },

      scoreAplication: {
        especificacao: "",
        rating: "",
        valor: 0,
        classificacao: "",
      },

      erro: false,
      sumario: [],
      resultado: {},
      libera: false,
    };
  },
  async mounted() {
    if (this.contexto.resultados.BOXATRPF005) {
      this.resultado = this.contexto.resultados.BOXATRPF005;
      this.libera = true;
      if (!this.contexto.resultados.BOXATRPF005.erro) {
        this.trataDados(this.contexto.resultados.BOXATRPF005);
      } else {
        this.erro = true;
      }
      this.encontraSumario(this.contexto.resultados.BOXATRPF005);
    } else {
      var objetoConsulta = {};
      this.contexto.inferido
        ? (objetoConsulta["cod_transacao_ciclica_pai"] =
            this.contexto.inferido["cod_transacao"])
        : "";
      this.campos["CPF"]
        ? (objetoConsulta["cpf_cnpj"] = this.campos["CPF"])
        : "";
      this.campos["DATA_REF"]
        ? (objetoConsulta["data_ref"] = this.campos["DATA_REF"])
        : "";
      this.campos["CEP"] ? (objetoConsulta["cep"] = this.campos["CEP"]) : "";
      objetoConsulta["cod_ref"] = "BOXATRPF005";
      objetoConsulta["tipo_pessoa"] = "pessoa_fisica";
      objetoConsulta["modo_resultado"] = "codigo";
      objetoConsulta["cod_agrupador"] = this.codigo_agrupador;
      await consultasService
        .realizaConsulta(objetoConsulta)
        .then((resultado) => {
          this.resultado = resultado;
          this.contexto.resultados["BOXATRPF005"] = { ...resultado };
        });
      this.libera = true;
      if (!this.resultado.erro) {
        this.trataDados(this.resultado);
      } else {
        this.erro = true;
      }
      this.encontraSumario(this.resultado);
    }
  },
  methods: {
    trataDados(resultado) {
      this.scorePropensity.especificacao =
        resultado.atributos.boxps001_especificacao;
      this.scorePropensity.rating = resultado.atributos.boxps001_rating;
      this.scorePropensity.valor = resultado.atributos.boxps001_score;
      this.scorePropensity.classificacao =
        resultado.atributos.boxps001_classificacao;

      this.scoreAplication.especificacao =
        resultado.atributos.swcsvar009_especificacao;
      this.scoreAplication.rating = resultado.atributos.swcsvar009_rating;
      this.scoreAplication.valor = resultado.atributos.swcsvar009_score;
      this.scoreAplication.classificacao =
        resultado.atributos.swcsvar009_classificacao;
    },
    encontraSumario(resultado) {
      const sumario_consulta = resultado.sumario_consulta.parametros;
      if (sumario_consulta.cpf) {
        this.sumario.push({
          Nome: "CPF",
          Valor: mascaras.cpf(sumario_consulta.cpf),
        });
      }
      if (sumario_consulta.cep) {
        this.sumario.push({
          Nome: "CEP",
          Valor: mascaras.cep(sumario_consulta.cep),
        });
      }
    },
  },
};
</script>
<style lang="scss" src="@/styles/components/box_resultados.scss" scoped></style>

<style lang="scss" scoped>
.bank-icon {
  font-size: 3rem;
  text-align: center;
  @include mediaQuery(medium) {
    font-size: 5rem;
  }
}
.dados {
  div {
    font-size: 0.9rem;
    @include flex-align-line();

    @include mediaQuery(medium) {
      font-size: 1.1rem;
      gap: 10px;
    }
  }

  .icon-off {
    color: $negative-red;
  }
}
.flex-align-center {
  @include flex-align-line();
}

.aviso-vazio {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
}
</style>
