<template>
  <div class="card">
    <div class="header">
      <div class="header__esq">
        <div class="titulo">
          Score Customizado de Propensão Óbito - Daycoval
          <span class="sumario" v-if="!this.resultado.erro && libera">
            <span>(</span>
            <span v-for="(param, index) in sumario" :key="param.Nome">
              {{ param.Nome }} : {{ param.Valor }}
              <span v-if="index != Object.keys(sumario).length - 1">,</span>
            </span>
            <span>)</span>
          </span>
        </div>
        <div class="subtitulo">Score de Propensão</div>
      </div>
      <div class="header__dir">
        <span class="material-icons icon-header">tips_and_updates</span>
      </div>
    </div>
    <div
      v-if="!this.resultado.erro && this.resultado.atributos != null && libera"
      class="corpo-resp"
    >
      <div class="title">{{ scorePotencial.especificacao }}</div>
      <div class="text">
        {{ scorePotencial.valor }}
      </div>
    </div>
    <div v-else-if="resultado.atributos == null" class="aviso-vazio">
      Nenhum resultado encontrado
    </div>
    <div v-else-if="!libera">
      <Loading />
    </div>
    <div v-else class="corpo-nao-encontrado">
      Não existe informação de fraude para o CPF informado
    </div>
  </div>
</template>

<script>
import { formataData, formataNome } from "@/helpers/utilitarios";
import { mascaras } from "@/helpers/mascaras";
// import Gauge from "@/components/Visuais/Gauge";
import { consultasService } from "@/services";
import Loading from "@/components/Loading";

export default {
  name: "BOXATRDAY001",
  props: {
    codigo_agrupador: String,
    campos: Object,
    contexto: Object,
  },
  components: {
    // Gauge,
    Loading,
  },
  data() {
    return {
      scorePotencial: {
        classificacao: "",
        especificacao: "",
        rating: "",
        valor: 0,
      },

      erro: false,
      sumario: [],
      resultado: {},
      libera: false,
    };
  },

  async mounted() {
    if (this.contexto.resultados.BOXATRDAY001) {
      this.resultado = this.contexto.resultados.BOXATRDAY001;
      this.libera = true;
      if (!this.contexto.resultados.BOXATRDAY001.erro) {
        this.trataDados(this.contexto.resultados.BOXATRDAY001);
      } else {
        this.erro = true;
      }
      this.encontraSumario(this.contexto.resultados.BOXATRDAY001);
    } else {
      var objetoConsulta = {};
      this.contexto.inferido
        ? (objetoConsulta["cod_transacao_ciclica_pai"] =
            this.contexto.inferido["cod_transacao"])
        : "";
      this.campos["CPF"]
        ? (objetoConsulta["cpf_cnpj"] = this.campos["CPF"])
        : "";
      this.campos["DATA_REF"]
        ? (objetoConsulta["data_ref"] = this.campos["DATA_REF"])
        : "";
      this.campos["CEP"] ? (objetoConsulta["cep"] = this.campos["CEP"]) : "";
      objetoConsulta["cod_ref"] = "BOXATRDAY001";
      objetoConsulta["tipo_pessoa"] = "pessoa_fisica";
      objetoConsulta["modo_resultado"] = "codigo";
      objetoConsulta["cod_agrupador"] = this.codigo_agrupador;
      await consultasService
        .realizaConsulta(objetoConsulta)
        .then((resultado) => {
          this.resultado = resultado;
          this.contexto.resultados["BOXATRDAY001"] = { ...resultado };
        });
      this.libera = true;
      if (!this.resultado.erro) {
        this.trataDados(this.resultado);
      } else {
        this.erro = true;
      }
      this.encontraSumario(this.resultado);
    }
  },
  methods: {
    trataDados(resultado) {
      this.scorePotencial.classificacao =
        resultado.atributos.boxpsday001_classificacao;
      this.scorePotencial.especificacao =
        resultado.atributos.boxpsday001_especificacao;
      this.scorePotencial.rating = resultado.atributos.boxpsday001_rating;
      this.scorePotencial.valor = resultado.atributos.boxpsday001_score;
    },

    encontraSumario(resultado) {
      const sumario_consulta = resultado.sumario_consulta.parametros;
      if (sumario_consulta.cpf) {
        this.sumario.push({
          Nome: "CPF",
          Valor: mascaras.cpf(sumario_consulta.cpf),
        });
      }
      if (sumario_consulta.cep) {
        this.sumario.push({
          Nome: "CEP",
          Valor: mascaras.cep(sumario_consulta.cep),
        });
      }
    },
  },
};
</script>
<style lang="scss" src="@/styles/components/box_resultados.scss" scoped></style>

<style lang="scss" scoped>
.corpo-resp {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;

  .title {
    font-size: 26px;
    margin: 0;
  }
  .text {
    font-size: 26px;
  }
}

.icon-header {
  font-size: 54px;
  color: #3d3939;
  position: relative;
  left: 10px;
}

.aviso-vazio {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
}
</style>
