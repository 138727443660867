<template>
  <div class="card">
    <div class="header">
      <div class="header__esq">
        <div class="titulo">
          Qualifica Endereço Macro Econômico
          <span
            class="sumario"
            v-if="
              !this.resultado.erro && this.resultado.atributos != null && libera
            "
          >
            <span>(</span>
            <span v-for="(param, index) in sumario" :key="param.Nome">
              {{ param.Nome }} : {{ param.Valor }}
              <span v-if="index != Object.keys(sumario).length - 1">,</span>
            </span>
            <span>)</span>
          </span>
        </div>
        <div class="subtitulo">
          Informações do endereço na visão empresarial macro econômica
        </div>
      </div>
      <div class="header__dir">
        <span class="material-icons icon">other_houses</span>
      </div>
    </div>
    <div v-if="!this.resultado.erro && libera" class="corpo">
      <div class="corpo__esq">
        <p class="item">
          <span class="key">
            Matriz ativa:
            {{ resultado.atributos.bk_304_004_pct_cnpj_ativa_matriz }}%
            <span class="material-icons help"
              >help_outline
              <md-tooltip md-direction="top">
                Porcentagem de CNPJs localizados no CEP consultado que possuem
                situação cadastral 'ativa' e são qualificados como matrizes
              </md-tooltip>
            </span>
          </span>

          <BarraProgresso
            :valor="resultado.atributos.bk_304_004_pct_cnpj_ativa_matriz"
            :valor_percent="
              resultado.atributos.bk_304_004_pct_cnpj_ativa_matriz
            "
            :max="100"
            indicador="inicio_atividade"
          />
        </p>
        <p class="item">
          <span class="key">
            Geral filial:
            {{ resultado.atributos.bk_304_004_pct_cnpj_geral_filial }}%
            <span class="material-icons help"
              >help_outline
              <md-tooltip md-direction="top">
                Porcentagem de CNPJs localizados no CEP consultado que são
                qualificados como filiais
              </md-tooltip>
            </span>
          </span>
          <BarraProgresso
            :valor="resultado.atributos.bk_304_004_pct_cnpj_geral_filial"
            :valor_percent="
              resultado.atributos.bk_304_004_pct_cnpj_geral_filial
            "
            :max="100"
            indicador="inicio_atividade"
          />
        </p>
        <p class="item">
          <span class="key">
            Opção simples optante:
            {{ resultado.atributos.bk_304_001_pct_cnpj_opcao_simples_optante }}%
            <span class="material-icons help"
              >help_outline
              <md-tooltip md-direction="top">
                Porcentagem de CNPJs localizados no CEP consultado que foram
                optantes do simples nacional
              </md-tooltip>
            </span>
          </span>
          <BarraProgresso
            :valor="
              resultado.atributos.bk_304_001_pct_cnpj_opcao_simples_optante
            "
            :valor_percent="
              resultado.atributos.bk_304_001_pct_cnpj_opcao_simples_optante
            "
            :max="100"
            indicador="inicio_atividade"
          />
        </p>
      </div>
      <div class="corpo__dir">
        <div class="porte">
          <span class="key">Porte</span>
          <Pie
            :label="'Porte'"
            :data="porte_data"
            :labels="['Demais', 'EPP', 'ME']"
          />
        </div>
        <div class="situacao-cadastral">
          <span class="key">Situação Cadastral</span>
          <Pie
            :label="'Porte'"
            :data="cadastral_data"
            :labels="['Ativa', 'Suspensa', 'Inapta']"
          />
        </div>
      </div>
      <!-- <div class="mapa">
                <iframe :src="mapa" frameborder="0"></iframe>
            </div> -->
      <div class="corpo-inferior"></div>
    </div>
    <div v-else-if="resultado.atributos == null" class="aviso-vazio">
      Nenhum resultado encontrado
    </div>
    <div v-else-if="!libera">
      <Loading />
    </div>
    <div v-else-if="resultado.erro != null" class="corpo-nao-encontrado">
      {{ resultado.erro.descricao }}
    </div>
  </div>
</template>

<script>
import { mascaras } from "@/helpers/mascaras";
import { consultasService } from "@/services";
import Loading from "@/components/Loading";
import BarraProgresso from "@/components/Visuais/BarraProgresso";
import Pie from "@/components/Visuais/Pie";

export default {
  name: "BOXATRGEO002",
  props: {
    codigo_agrupador: String,
    campos: Object,
    contexto: Object,
  },
  components: {
    Loading,
    BarraProgresso,
    Pie,
  },
  data() {
    return {
      sumario: [],
      erro: false,
      resultado: {},
      libera: false,
      porte_data: [],
      cadastral_data: [],
    };
  },
  async mounted() {
    if (this.contexto.resultados.BOXATRGEO002) {
      this.libera = true;
      this.resultado = this.contexto.resultados.BOXATRGEO002;
      this.criaPorte();
      this.criaCadastral();

      if (this.contexto.resultados.BOXATRGEO002.erro) {
        this.erro = true;
      } else {
      }
      this.encontraSumario(this.contexto.resultados.BOXATRGEO002);
    } else {
      var objetoConsulta = {};
      this.campos["CNPJ"]
        ? (objetoConsulta["cpf_cnpj"] = this.campos["CNPJ"])
        : "";
      this.campos["DATA_REF"]
        ? (objetoConsulta["data_ref"] = this.campos["DATA_REF"])
        : "";
      this.campos["CEP"] ? (objetoConsulta["cep"] = this.campos["CEP"]) : "";
      this.contexto.inferido
        ? (objetoConsulta["cod_transacao_ciclica_pai"] =
            this.contexto.inferido["cod_transacao"])
        : "";
      objetoConsulta["cod_ref"] = "BOXATRGEO002";
      this.campos["CNPJ"]
        ? (objetoConsulta["tipo_pessoa"] = "pessoa_juridica")
        : (objetoConsulta["tipo_pessoa"] = "pessoa_fisica");

      objetoConsulta["modo_resultado"] = "codigo";
      objetoConsulta["cod_agrupador"] = this.codigo_agrupador;

      await consultasService
        .realizaConsulta(objetoConsulta)
        .then((resultado) => {
          this.resultado = { ...resultado };
          this.contexto.resultados["BOXATRGEO002"] = { ...resultado };
        });
      this.libera = true;
      if (this.resultado.erro) {
        this.erro = true;
      }
      this.encontraSumario(this.resultado);
      this.criaPorte();
      this.criaCadastral();
    }
  },
  methods: {
    criaCadastral() {
      this.cadastral_data = [];
      this.cadastral_data.push(
        this.resultado.atributos.bk_304_001_pct_cnpj_situacao_cadastral_ativa
      );
      this.cadastral_data.push(
        this.resultado.atributos.bk_304_001_pct_cnpj_situacao_cadastral_suspensa
      );
      this.cadastral_data.push(
        this.resultado.atributos.bk_304_001_pct_cnpj_situacao_cadastral_inapta
      );
    },

    criaPorte() {
      this.porte_data = [];
      this.porte_data.push(
        this.resultado.atributos.bk_304_004_pct_cnpj_geral_porte_demais
      );
      this.porte_data.push(
        this.resultado.atributos.bk_304_004_pct_cnpj_geral_porte_epp
      );
      this.porte_data.push(
        this.resultado.atributos.bk_304_004_pct_cnpj_geral_porte_me
      );
    },
    encontraSumario(resultado) {
      const sumario_consulta = resultado.sumario_consulta.parametros;
      if (sumario_consulta.cep) {
        this.sumario.push({
          Nome: "CEP",
          Valor: mascaras.cep(sumario_consulta.cep),
        });
      } else if (sumario_consulta.cpf) {
        this.sumario.push({
          Nome: "CPF",
          Valor: mascaras.cpf(sumario_consulta.cpf),
        });
      }
      if (sumario_consulta.cnpj) {
        this.sumario.push({
          Nome: "CNPJ",
          Valor: mascaras.cnpj(sumario_consulta.cnpj),
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  .corpo {
    align-items: start;
    font-size: 0.9rem;
    grid-template-columns: 1fr 1.5fr;
    .item {
      width: 75%;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .key {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }

    &__esq {
      align-items: start;
      gap: 10px;
    }

    &__dir {
      gap: 30px;
      display: grid;
      grid-template-columns: 1fr 1fr;

      .porte {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        color: $bg-sidebar;
        font-size: 1rem;
        font-weight: 500;

        .item {
          padding-left: 10px;
        }
      }
      .situacao-cadastral {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        color: $bg-sidebar;
        font-size: 1rem;
        font-weight: 500;
        .item-cadastral {
          padding-left: 10px;
        }
      }
    }

    .corpo-inferior {
      grid-column: 1/3;
      display: flex;
      justify-content: center;
    }

    @media (max-width: 1024px) {
      .item,
      .situacao-cadastral {
        width: 100%;
      }
    }
  }
}

.aviso-vazio {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
}
</style>

<style lang="scss" src="@/styles/components/box_resultados.scss" scoped></style>
