import { render, staticRenderFns } from "./BOXATRPF002.vue?vue&type=template&id=6a4e572a&scoped=true&"
import script from "./BOXATRPF002.vue?vue&type=script&lang=js&"
export * from "./BOXATRPF002.vue?vue&type=script&lang=js&"
import style0 from "@/styles/components/box_resultados.scss?vue&type=style&index=0&id=6a4e572a&prod&lang=scss&scoped=true&"
import style1 from "./BOXATRPF002.vue?vue&type=style&index=1&id=6a4e572a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a4e572a",
  null
  
)

export default component.exports