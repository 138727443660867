<template>
  <div class="card">
    <div class="header">
      <div class="header__esq">
        <div class="titulo">
          Qualifica Endereço - PF
          <span class="sumario" v-if="!this.resultado.erro && libera">
            <span>(</span>
            <span v-for="(param, index) in sumario" :key="param.Nome">
              {{ param.Nome }} : {{ param.Valor }}
              <span v-if="index != Object.keys(sumario).length - 1">,</span>
            </span>
            <span>)</span>
          </span>
        </div>
        <div class="subtitulo">Informações de qualidade do endereço</div>
      </div>
      <div class="header__dir">
        <span class="material-icons icon">other_houses</span>
      </div>
    </div>
    <div v-if="!this.resultado.erro && libera" class="corpo">
      <div class="corpo-esq">
        <Gauge
          :titulo="score.especificacao"
          :valor="score.valor"
          :classificacao="score.classificacao"
          :rating="score.rating"
        />
      </div>

      <div class="corpo__dir">
        <div class="indicador-eds">
          <div v-if="indicador_eds">Este CEP está em um EDS</div>
          <div v-else>Este CEP está fora de um EDS</div>
        </div>
        <div class="eds">
          <div class="eds__values">
            <p>{{ qtde_eds_1_km }}</p>
            Endereços desfavorecidos socialmente a menos de 1km
          </div>
          <div class="eds__values">
            <p>{{ qtde_efs_1_km }}</p>
            Endereços favorecidos socialmente a menos de 1km
          </div>
        </div>
      </div>

      <div class="line"></div>
      <div class="mapa">
        <iframe :src="mapa" frameborder="0"></iframe>
      </div>
    </div>
    <div v-else-if="resultado.atributos == null" class="aviso-vazio">
      Não existe informação de endereço para o CPF informado
    </div>
    <div v-else-if="!libera">
      <Loading />
    </div>
    <div v-else class="corpo-nao-encontrado">
      Não existe informação de endereço para o CPF informado
    </div>
  </div>
</template>

<script>
import Gauge from "@/components/Visuais/Gauge";
import { mascaras } from "@/helpers/mascaras";
import { consultasService } from "@/services";
import Loading from "@/components/Loading";

export default {
  name: "BOXATRPF002",
  props: {
    campos: Object,
    contexto: Object,
    codigo_agrupador: String,
  },
  components: {
    Gauge,
    Loading,
  },
  data() {
    return {
      indicador_eds: false,
      qtde_eds_1_km: 0,
      qtde_efs_1_km: 0,
      score: {
        valor: 0,
        classificacao: "",
        especificacao: "",
        rating: "",
      },
      score_percent: 0,
      mapa: "",
      sumario: [],
      resultado: {},
      erro: false,
      libera: false,
    };
  },
  created() {
    window.addEventListener("resize", this.onWindowResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onWindowResize);
  },
  async mounted() {
    if (this.contexto.resultados.BOXATRPF002) {
      this.resultado = this.contexto.resultados.BOXATRPF002;
      this.libera = true;
      if (!this.contexto.resultados.BOXATRPF002.erro) {
        this.trataDados(this.contexto.resultados.BOXATRPF002);
      } else {
        this.erro = true;
      }
      this.encontraSumario(this.contexto.resultados.BOXATRPF002);
    } else {
      var objetoConsulta = {};
      this.campos["CPF"]
        ? (objetoConsulta["cpf_cnpj"] = this.campos["CPF"])
        : "";
      this.campos["DATA_REF"]
        ? (objetoConsulta["data_ref"] = this.campos["DATA_REF"])
        : "";
      this.campos["CEP"] ? (objetoConsulta["cep"] = this.campos["CEP"]) : "";
      this.contexto.inferido
        ? (objetoConsulta["cod_transacao_ciclica_pai"] =
            this.contexto.inferido["cod_transacao"])
        : "";
      objetoConsulta["cod_ref"] = "BOXATRPF002";
      objetoConsulta["tipo_pessoa"] = "pessoa_fisica";
      objetoConsulta["modo_resultado"] = "codigo";
      objetoConsulta["cod_agrupador"] = this.codigo_agrupador;
      await consultasService
        .realizaConsulta(objetoConsulta)
        .then((resultado) => {
          this.resultado = resultado;
          this.contexto.resultados["BOXATRPF002"] = { ...resultado };
        });
      this.libera = true;
      if (!this.resultado.erro) {
        this.trataDados(this.resultado);
      } else {
        this.erro = true;
      }
      this.encontraSumario(this.resultado);
    }
  },
  methods: {
    async trataDados(resultado) {
      // await setTimeout(() => {
        this.mapa =
        "https://api.outbox.com.br/Mapa/EDS.html?usuario=" +
        resultado.sumario_consulta.nome_usuario +
        "&codigoTransacao=" +
        resultado.sumario_consulta.cod_transacao +
        "&zoom=15";
      // }, 1000);
      
      this.onWindowResize();
      this.indicador_eds = resultado.atributos.bk_303_001_indicador_eds;
      this.qtde_eds_1_km = resultado.atributos.bk_303_001_qtde_eds_1_km;
      this.qtde_efs_1_km = resultado.atributos.bk_303_003_qtde_efs_1_km;

      this.score.valor = resultado.atributos.boxcep001_score;
      this.score.classificacao = resultado.atributos.boxcep001_classificacao;
      this.score.especificacao = resultado.atributos.boxcep001_especificacao;
      this.score.rating = resultado.atributos.boxcep001_rating;
    },
    encontraSumario(resultado) {
      const sumario_consulta = resultado.sumario_consulta.parametros;
      if (sumario_consulta.cpf) {
        this.sumario.push({
          Nome: "CPF",
          Valor: mascaras.cpf(sumario_consulta.cpf),
        });
      }
      if (sumario_consulta.cep) {
        this.sumario.push({
          Nome: "CEP",
          Valor: mascaras.cep(sumario_consulta.cep),
        });
      }
    },
    onWindowResize() {
      this.windowWidth = window.innerWidth;

      if (window.innerWidth < 600) {
        this.mapa = this.mapa.replace("zoom=15", "zoom=14");
      } else {
        this.mapa = this.mapa.replace("zoom=14", "zoom=15");
      }
    },
  },
};
</script>
<style lang="scss" src="@/styles/components/box_resultados.scss" scoped></style>

<style lang="scss" scoped>
.indicador-eds {
  text-align: center;
}

.eds__values {
  @include flex-center(row);
  gap: 20px;
  font-size: 0.9rem;
  font-weight: 500;

  @include mediaQuery(medium) {
    justify-content: left;
  }

  p {
    line-height: 50px;
    font-size: 3rem;
    text-align: center;
    font-weight: 600;
  }
}

.line {
  height: 20px;
  border-bottom: 0.5px solid rgb(116, 107, 107);

  @include mediaQuery(medium) {
    height: 30px;
    grid-column: 1/3;
  }
}

.mapa {
  @include flex-center(row);

  @include mediaQuery(medium) {
    grid-column: 1/3;
  }
}

iframe {
  width: 100%;
  height: 500px;
  border-radius: 10px;
}

.aviso-vazio {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
}
</style>
