<template>
  <div class="card">
    <div class="header">
      <div class="header__esq">
        <div class="titulo">
          Qualifica Endereço - PJ

          <span
            class="sumario"
            v-if="
              !this.resultado.erro && this.resultado.atributos != null && libera
            "
          >
            <span>(</span>
            <span v-for="(param, index) in sumario" :key="param.Nome">
              {{ param.Nome }} : {{ param.Valor }}
              <span v-if="index != Object.keys(sumario).length - 1">,</span>
            </span>
            <span>)</span>
          </span>
        </div>
        <div class="subtitulo">Informações de qualidade do endereço</div>
      </div>
      <div class="header__dir">
        <span class="material-icons icon">other_houses</span>
      </div>
    </div>
    <div v-if="!this.resultado.erro && libera" class="corpo">
      <div class="corpo__esq">
        <Gauge
          :titulo="score.especificacao"
          :valor="score.valor"
          :classificacao="score.classificacao"
          :rating="score.rating"
        />
      </div>
      <div class="corpo__dir">
        <div>
          <div v-if="indicador_eds" class="indicador-eds">
            Este CEP está em um EDS
          </div>
          <div v-else class="indicador-eds">Este CEP está fora de um EDS</div>
        </div>
        <div class="ed">
          <span>{{ qtde_eds_1km }}</span>
          Endereços desfavorecidos socialmente a menos de 1km
        </div>

        <div class="capital-social">
          <p>
            Visão capital social
            <span class="material-icons help"
              >help_outline
              <md-tooltip md-direction="top">
                Percentil relativo à tendência linear do total do capital social
                dos CNPJs que possuem situação cadastral 'ativa' e tempo de
                duração de atividade inferior a 1800 dias, em relação à região
                formada pelos 5 primeiros dígitos do CEP consultado.
              </md-tooltip>
            </span>
            :
          </p>
          <div>
            <BarraProgresso
              :valor="capital_social"
              :valor_percent="capital_social * 10"
              :max="10"
              indicador="capital_social"
            />
            <div class="texto-explicativo">{{ texto_capital_social }}</div>
          </div>
        </div>
        <div class="inicio-atividade">
          <p>
            Indicador de abertura de empresas

            <span class="material-icons help"
              >help_outline
              <md-tooltip md-direction="top">
                Percentil relativo tendência linear dos CNPJs que possuem
                situação cadastral 'ativa' e tempo de duração de atividade
                inferior a 1800 dias, em relação à região formada pelos 5
                primeiros dígitos do CEP consultado.
              </md-tooltip>
            </span>
            :
          </p>
          <div>
            <BarraProgresso
              :valor="inicio_atividade"
              :valor_percent="inicio_atividade * 10"
              :max="10"
              indicador="inicio_atividade"
            />
            <div class="texto-explicativo">{{ texto_inicio_atividade }}</div>
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div class="mapa">
        <iframe :src="mapa" frameborder="0"></iframe>
      </div>
    </div>
    <div v-else-if="resultado.atributos == null" class="aviso-vazio">
      Nenhum resultado encontrado
    </div>
    <div v-else-if="!libera">
      <Loading />
    </div>
    <div v-else-if="resultado.erro != null" class="corpo-nao-encontrado">
      {{ resultado.erro.descricao }}
    </div>
  </div>
</template>

<script>
import { mascaras } from "@/helpers/mascaras";
import BarraProgresso from "../Visuais/BarraProgresso.vue";
import Gauge from "../Visuais/Gauge.vue";
import { consultasService } from "@/services";
import Loading from "@/components/Loading";

export default {
  name: "BOXATRPJ002",
  props: {
    codigo_agrupador: String,
    campos: Object,
    contexto: Object,
  },
  components: {
    BarraProgresso,
    Gauge,
    Loading,
  },
  data() {
    return {
      indicador_eds: false,
      qtde_eds_1km: 0,
      score: {
        valor: 0,
        classificacao: "",
        especificacao: "",
        rating: "",
      },
      inicio_atividade: 0,
      capital_social: 0,
      score_percent: 0,
      sumario: [],
      mapa: "",
      texto_capital_social: "",
      texto_inicio_atividade: "",
      erro: false,
      resultado: {},
      libera: false,
    };
  },
  async mounted() {
    if (this.contexto.resultados.BOXATRPJ002) {
      this.libera = true;
      if (!this.contexto.resultados.BOXATRPJ002.erro) {
        this.trataDados(this.contexto.resultados.BOXATRPJ002);
      } else {
        this.erro = true;
      }
      this.encontraSumario(this.contexto.resultados.BOXATRPJ002);
    } else {
      var objetoConsulta = {};
      this.campos["CNPJ"]
        ? (objetoConsulta["cpf_cnpj"] = this.campos["CNPJ"])
        : "";
      this.campos["CEP"] ? (objetoConsulta["cep"] = this.campos["CEP"]) : "";
      this.campos["DATA_REF"]
        ? (objetoConsulta["data_ref"] = this.campos["DATA_REF"])
        : "";
      this.contexto.inferido
        ? (objetoConsulta["cod_transacao_ciclica_pai"] =
            this.contexto.inferido["cod_transacao"])
        : "";
      objetoConsulta["cod_ref"] = "BOXATRPJ002";
      objetoConsulta["tipo_pessoa"] = "pessoa_juridica";
      objetoConsulta["modo_resultado"] = "codigo";
      objetoConsulta["cod_agrupador"] = this.codigo_agrupador;

      await consultasService
        .realizaConsulta(objetoConsulta)
        .then((resultado) => {
          this.resultado = resultado;
          this.contexto.resultados["BOXATRPJ002"] = { ...resultado };
        });
      this.libera = true;
      if (!this.resultado.erro) {
        this.trataDados(this.resultado);
      } else {
        this.erro = true;
      }
      this.encontraSumario(this.resultado);
    }
  },
  methods: {
    trataDados(resultado) {
      setTimeout(() => {
        this.mapa =
          "https://api.outbox.com.br/Mapa/EDS.html?usuario=" +
          resultado.sumario_consulta.nome_usuario +
          "&codigoTransacao=" +
          resultado.sumario_consulta.cod_transacao +
          "&zoom=15";
      }, 1000);
      this.onWindowResize();

      this.indicador_eds = resultado.atributos.bk_303_001_indicador_eds;
      this.qtde_eds_1km = resultado.atributos.bk_303_001_qtde_eds_1_km;
      this.capital_social =
        resultado.atributos.bk_304_004_pct_inclinacao_capital_social_ativa;
      this.inicio_atividade =
        resultado.atributos.bk_304_004_pct_inclinacao_inicio_atividade_ativa;

      this.score.valor = resultado.atributos.boxcep001_score;
      this.score.classificacao = resultado.atributos.boxcep001_classificacao;
      this.score.especificacao = resultado.atributos.boxcep001_especificacao;
      this.score.rating = resultado.atributos.boxcep001_rating;

      this.decideTextoCapital();
      this.decideTextoAbertura();
    },
    encontraSumario(resultado) {
      const sumario_consulta = resultado.sumario_consulta.parametros;
      if (sumario_consulta.cnpj) {
        this.sumario.push({
          Nome: "CNPJ",
          Valor: mascaras.cnpj(sumario_consulta.cnpj),
        });
      }
      if (sumario_consulta.cep) {
        this.sumario.push({
          Nome: "CEP",
          Valor: mascaras.cep(sumario_consulta.cep),
        });
      }
    },
    decideTextoCapital() {
      switch (this.capital_social) {
        case 0:
        case 1:
        case 2:
          this.texto_capital_social = "Baixo";
          break;
        case 3:
        case 4:
        case 5:
        case 6:
          this.texto_capital_social = "Moderado";
          break;
        case 7:
        case 8:
        case 9:
        case 10:
          this.texto_capital_social = "Alto";
          break;
      }
    },
    decideTextoAbertura() {
      switch (this.inicio_atividade) {
        case 0:
        case 1:
        case 2:
        case 3:
          this.texto_inicio_atividade = "Baixo";
          break;
        case 4:
        case 5:
        case 6:
          this.texto_inicio_atividade = "Moderado";
          break;
        case 7:
        case 8:
        case 9:
        case 10:
          this.texto_inicio_atividade = "Alto";
          break;
      }
    },
    onWindowResize() {
      this.windowWidth = window.innerWidth;

      if (window.innerWidth < 600) {
        this.mapa = this.mapa.replace("zoom=15", "zoom=14");
      } else {
        this.mapa = this.mapa.replace("zoom=14", "zoom=15");
      }
    },
  },
  created() {
    window.addEventListener("resize", this.onWindowResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onWindowResize);
  },
};
</script>
<style lang="scss" src="@/styles/components/box_resultados.scss" scoped></style>

<style lang="scss" scoped>
.ed {
  @include flex-align-line();
  font-size: 0.8rem;
  column-gap: 20px;
  font-weight: 500;

  @include mediaQuery(medium) {
    font-size: 0.9rem;
  }
  span {
    font-size: 3rem;
    text-align: center;
    font-weight: 600;
  }
}

.capital-social,
.inicio-atividade {
  @include flex-column();
  gap: 15px;
  width: 90%;
  p {
    display: flex;
    align-items: center;
    gap: 3px;
    width: fit-content;
  }

  .help {
    font-size: 1.3rem;
  }
}

.aviso-vazio {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
}
</style>
