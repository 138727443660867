import { render, staticRenderFns } from "./BOXATRPF006.vue?vue&type=template&id=e77927cc&scoped=true&"
import script from "./BOXATRPF006.vue?vue&type=script&lang=js&"
export * from "./BOXATRPF006.vue?vue&type=script&lang=js&"
import style0 from "@/styles/components/box_resultados.scss?vue&type=style&index=0&id=e77927cc&prod&lang=scss&scoped=true&"
import style1 from "./BOXATRPF006.vue?vue&type=style&index=1&id=e77927cc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e77927cc",
  null
  
)

export default component.exports