<template>
  <div class="card">
    <div class="header">
      <div class="header__esq">
        <div class="titulo">
          Perfil de negócios - PJ
          <span class="sumario" v-if="!this.resultado.erro && libera">
            <span>(</span>
            <span v-for="(param, index) in sumario" :key="param.Nome">
              {{ param.Nome }} : {{ param.Valor }}
              <span v-if="index != Object.keys(sumario).length - 1">,</span>
            </span>
            <span>)</span>
          </span>
        </div>
        <div class="subtitulo">
          Informações dos sócios e empresas atrelados a CNPJ
        </div>
      </div>
      <div class="header__dir">
        <span class="material-icons icon">cases</span>
      </div>
    </div>
    <div
      v-if="!this.resultado.erro && this.resultado.atributos != null && libera"
      class="corpo"
    >
      <div class="corpo__esq">
        <p class="item">
          <span class="key">Capital social: </span>
          <span class="dado capital_social"
            >R${{
              parseInt(
                resultado.atributos.bk_201_001_capital_social
              ).toLocaleString("pt-BR")
            }}</span
          >
        </p>
        <p class="item">
          <span class="key">Simples: </span>

          <span class="dado">{{
            resultado.atributos.bk_201_001_opcao_simples
          }}</span>
        </p>
        <p class="item">
          <span class="key">Tempo de atuação: </span>
          <span class="dado"
            >{{ resultado.atributos.bk_201_001_tempo_anos_data_ref }} anos</span
          >
        </p>
        <span class="scoreBox">
          <Gauge
            :titulo="resultado.atributos.boxcsvar011_especificacao"
            :valor="resultado.atributos.boxcsvar011_score"
            :classificacao="resultado.atributos.boxcsvar011_classificacao"
            :rating="resultado.atributos.boxcsvar011_rating"
          />
        </span>
      </div>
      <div class="corpo__dir">
        <p class="item">
          <span class="key">Faturamento Presumido: </span>
          <span class="dado capital_social"
            >R${{
              parseInt(
                resultado.atributos.boxprpj002_faturamento_presumido
              ).toLocaleString("pt-BR")
            }}</span
          >
        </p>
        <p class="item">
          <span class="key">Elegibilidade: </span>
          <span class="dado">{{
            resultado.atributos.boxprpj002_elegibilidade
          }}</span>
        </p>
        <p class="item">
          <span class="key">Porte: </span>
          <span
            class="dado"
            v-if="!resultado.atributos.bk_201_001_indicador_opcao_mei"
            >{{ resultado.atributos.bk_201_001_porte }}</span
          >
          <span class="dado" v-else>Micro Empreendedor Individual</span>
        </p>
        <p class="item">
          <span class="key">Qualificação responsável: </span>
          <span class="dado">{{
            resultado.atributos.bk_201_001_qualificacao_responsavel
          }}</span>
        </p>
        <div class="distribuicao">
          Distribuição de CNPJ's por região
          <Pie
            :data="dados_regioes"
            :labels="labels_regioes"
            :position="'left'"
          />
        </div>
      </div>
      <!-- <div class="mapa">
                  <iframe :src="mapa" frameborder="0"></iframe>
              </div> -->
    </div>
    <div v-else-if="resultado.atributos == null" class="aviso-vazio">
      Nenhum resultado encontrado
    </div>
    <div v-else-if="!libera">
      <Loading />
    </div>
    <div v-else-if="resultado.erro != null" class="corpo-nao-encontrado">
      {{ resultado.erro.descricao }}
    </div>
  </div>
</template>

<script>
import { mascaras } from "@/helpers/mascaras";
import { utilitarios } from "@/helpers/utilitarios";
import { consultasService } from "@/services";
import Loading from "@/components/Loading";
import Gauge from "@/components/Visuais/Gauge.vue";
import Pie from "@/components/Visuais/Pie.vue";

export default {
  name: "BOXATRPJ003",
  props: {
    codigo_agrupador: String,
    campos: Object,
    contexto: Object,
  },
  components: {
    Loading,
    Gauge,
    Pie,
  },
  data() {
    return {
      sumario: [],
      erro: false,
      resultado: {},
      libera: false,
      dados_regioes: [],
      labels_regioes: [],
    };
  },
  async mounted() {
    if (this.contexto.resultados.BOXATRPJ003) {
      this.libera = true;
      this.resultado = this.contexto.resultados.BOXATRPJ003;

      if (this.contexto.resultados.BOXATRPJ003.erro) {
        this.erro = true;
      } else {
        this.resultado.atributos.bk_201_001_capital_social =
          utilitarios.formataValor(
            this.resultado.atributos.bk_201_001_capital_social
          );
      }
      this.encontraSumario(this.contexto.resultados.BOXATRPJ003);
    } else {
      var objetoConsulta = {};
      this.campos["CNPJ"]
        ? (objetoConsulta["cpf_cnpj"] = this.campos["CNPJ"])
        : "";
      this.campos["DATA_REF"]
        ? (objetoConsulta["data_ref"] = this.campos["DATA_REF"])
        : "";
      this.contexto.inferido
        ? (objetoConsulta["cod_transacao_ciclica_pai"] =
            this.contexto.inferido["cod_transacao"])
        : "";
      objetoConsulta["cod_ref"] = "BOXATRPJ003";
      objetoConsulta["tipo_pessoa"] = "pessoa_juridica";
      objetoConsulta["modo_resultado"] = "codigo";
      objetoConsulta["cod_agrupador"] = this.codigo_agrupador;

      await consultasService
        .realizaConsulta(objetoConsulta)
        .then((resultado) => {
          this.resultado = resultado;
          this.contexto.resultados["BOXATRPJ003"] = { ...resultado };
          if (this.resultado.erro) {
            this.erro = true;
          } else {
            this.resultado.atributos.bk_201_001_capital_social =
              utilitarios.formataValor(
                resultado.atributos.bk_201_001_capital_social
              );
          }
        });
      this.libera = true;
      this.encontraSumario(this.resultado);
    }

    if (!this.resultado.erro) {
      this.dados_regioes = [
        this.resultado.atributos.bk_201_002_pct_cnpj_aglomerado_uf_regiao_norte,
        this.resultado.atributos
          .bk_201_002_pct_cnpj_aglomerado_uf_regiao_nordeste,
        this.resultado.atributos
          .bk_201_002_pct_cnpj_aglomerado_uf_regiao_sudeste,
        this.resultado.atributos
          .bk_201_002_pct_cnpj_aglomerado_uf_regiao_centro_oeste,
        this.resultado.atributos.bk_201_002_pct_cnpj_aglomerado_uf_regiao_sul,
        this.resultado.atributos
          .bk_201_002_pct_cnpj_aglomerado_uf_internacional,
      ];

      this.labels_regioes = [
        "Norte",
        "Nordeste",
        "Sudeste",
        "Centro Oeste",
        "Sul",
        "Internacional",
      ];
      for (let i = 0; i < this.dados_regioes.length; i++) {
        if (this.dados_regioes[i] == 0) {
          this.dados_regioes.splice(i, 1);
          this.labels_regioes.splice(i, 1);
          i--;
        }
      }
    }
  },
  methods: {
    encontraSumario(resultado) {
      const sumario_consulta = resultado.sumario_consulta.parametros;
      if (sumario_consulta.cnpj) {
        this.sumario.push({
          Nome: "CNPJ",
          Valor: mascaras.cnpj(sumario_consulta.cnpj),
        });
      }
    },
  },
};
</script>
<style lang="scss" src="@/styles/components/box_resultados.scss" scoped></style>

<style lang="scss" scoped>
.corpo {
  align-items: flex-start;

  &__esq {
    padding-left: 1.5rem;
    align-items: flex-start;
    gap: 10px;
    font-size: 1rem;
    .gauge-box {
      width: fit-content;
    }
    @include mediaQueryMax(small) {
      align-items: center;
    }

    .item {
      @include mediaQueryMax(small) {
        @include flex-center(row);
        // gap: 10px;
      }
      .capital_social {
        color: $positive-green;
        font-size: 1.3rem;
        cursor: default;
      }
    }
    .scoreBox {
      margin: 40px 0 0 0;
    }
  }

  &__dir {
    gap: 10px;
    align-items: flex-start;
    font-size: 1rem;

    .distribuicao {
      padding-top: 5px;
      text-align: center;
    }
    @include mediaQueryMax(small) {
      align-items: center;
    }
    .capital_social {
      color: $positive-green;
      font-size: 1.3rem;
      cursor: default;
    }
  }
}

.aviso-vazio {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
}
</style>
