<template>
  <div class="card">
    <div class="header">
      <div class="header__esq">
        <div class="titulo">
          Relações Societárias - PJ
          <span class="sumario" v-if="!this.resultado.erro && libera">
            <span>(</span>
            <span v-for="(param, index) in sumario" :key="param.Nome">
              {{ param.Nome }} : {{ param.Valor }}
              <span v-if="index != Object.keys(sumario).length - 1">,</span>
            </span>
            <span>)</span>
          </span>
        </div>
        <div class="subtitulo">
          Retorna informações dos sócios e empresas atrelados ao CNPJ
        </div>
      </div>
      <div class="header__dir">
        <span class="material-icons icon">handshake</span>
      </div>
    </div>
    <div
      v-if="
        !this.resultado.erro != null &&
        this.resultado.atributos != null &&
        libera
      "
      class="corpo"
    >
      <div class="corpo__esq">
        <div>
          <p class="item">
            <span class="key">Data entrada mais antiga: </span>
            <span class="dado">{{
              resultado.atributos.bk_203_002_data_entrada_mais_antiga
            }}</span>
          </p>
          <span class="qnt-dias"
            >{{
              resultado.atributos.bk_203_002_tempo_dias_entrada_mais_antiga
            }}
            dias atrás</span
          >
        </div>
        <div>
          <p class="item">
            <span class="key">Data entrada mais recente: </span>
            <span class="dado">{{
              resultado.atributos.bk_203_002_data_entrada_mais_recente
            }}</span>
          </p>
          <span class="qnt-dias"
            >{{
              resultado.atributos.bk_203_002_tempo_dias_entrada_mais_recente
            }}
            dias atrás</span
          >
        </div>
        <div>
          <div class="item coeficiente">
            <span class="dado">{{
              resultado.atributos
                .bk_203_002_coef_angular_relacoes_societarias_01_a_05_anos
            }}</span>
            <div class="key">
              <span class="key"
                >Coeficiente angular de relações societárias</span
              >
              <span class="sub-key">1 a 5 anos</span>
            </div>
          </div>
        </div>
      </div>
      <div class="corpo__dir">
        <p class="item">
          <span class="material-icons icone">connecting_airports</span>
          <span class="key">Quantidade de sócios estrangeiros: </span>
          <span class="dado">{{
            resultado.atributos.bk_203_002_qtde_tipo_socio_estrangeiro
          }}</span>
        </p>
        <p class="item">
          <span class="material-icons icone">person</span>
          <span class="key">Quantidade de sócios pessoa física: </span>
          <span class="dado">{{
            resultado.atributos.bk_203_002_qtde_tipo_socio_pessoa_fisica
          }}</span>
        </p>
        <p class="item">
          <span class="material-icons icone">business</span>

          <span class="key">Quantidade de sócios pessoa jurídica: </span>
          <span class="dado">{{
            resultado.atributos.bk_203_002_qtde_tipo_socio_estrangeiro
          }}</span>
        </p>
      </div>

      <!-- <div class="mapa">
                <iframe :src="mapa" frameborder="0"></iframe>
            </div> -->
    </div>
    <div v-else-if="resultado.atributos == null" class="aviso-vazio">
      Nenhum resultado encontrado
    </div>
    <div v-else-if="!libera">
      <Loading />
    </div>
    <div v-else-if="resultado.erro != null" class="corpo-nao-encontrado">
      {{ resultado.erro.descricao }}
    </div>
  </div>
</template>

<script>
import { mascaras } from "@/helpers/mascaras";
import { utilitarios } from "@/helpers/utilitarios";
import { consultasService } from "@/services";
import Loading from "@/components/Loading";
export default {
  name: "BOXATRPJ004",
  props: {
    codigo_agrupador: String,
    campos: Object,
    contexto: Object,
  },
  components: {
    Loading,
  },
  data() {
    return {
      sumario: [],
      erro: false,
      resultado: {},
      libera: false,
    };
  },
  async mounted() {
    if (this.contexto.resultados.BOXATRPJ004) {
      this.libera = true;
      this.resultado = this.contexto.resultados.BOXATRPJ004;
      if (this.resultado.atributos != null) {
        this.resultado.atributos.bk_203_002_data_entrada_mais_antiga =
          utilitarios.formataData(
            this.resultado.atributos.bk_203_002_data_entrada_mais_antiga
          );
        this.resultado.atributos.bk_203_002_data_entrada_mais_recente =
          utilitarios.formataData(
            this.resultado.atributos.bk_203_002_data_entrada_mais_recente
          );
      }
      if (this.contexto.resultados.BOXATRPJ004.erro) {
        this.erro = true;
      } else {
      }
      this.encontraSumario(this.contexto.resultados.BOXATRPJ004);
    } else {
      var objetoConsulta = {};
      this.campos["CNPJ"]
        ? (objetoConsulta["cpf_cnpj"] = this.campos["CNPJ"])
        : "";
      this.campos["DATA_REF"]
        ? (objetoConsulta["data_ref"] = this.campos["DATA_REF"])
        : "";
      this.campos["CEP"] ? (objetoConsulta["cep"] = this.campos["CEP"]) : "";
      this.contexto.inferido
        ? (objetoConsulta["cod_transacao_ciclica_pai"] =
            this.contexto.inferido["cod_transacao"])
        : "";
      objetoConsulta["cod_ref"] = "BOXATRPJ004";
      objetoConsulta["tipo_pessoa"] = "pessoa_juridica";
      objetoConsulta["modo_resultado"] = "codigo";
      objetoConsulta["cod_agrupador"] = this.codigo_agrupador;

      await consultasService
        .realizaConsulta(objetoConsulta)
        .then((resultado) => {
          this.resultado = { ...resultado };
          this.contexto.resultados["BOXATRPJ004"] = { ...resultado };
          this.libera = true;
        });
      if (this.resultado.erro) {
        this.erro = true;
      } else {
        if (this.resultado.atributos != null) {
          this.resultado.atributos.bk_203_002_data_entrada_mais_antiga =
            utilitarios.formataData(
              this.resultado.atributos.bk_203_002_data_entrada_mais_antiga
            );
          this.resultado.atributos.bk_203_002_data_entrada_mais_recente =
            utilitarios.formataData(
              this.resultado.atributos.bk_203_002_data_entrada_mais_recente
            );
        }
      }
      this.encontraSumario(this.resultado);
    }
  },
  methods: {
    encontraSumario(resultado) {
      const sumario_consulta = resultado.sumario_consulta.parametros;
      if (sumario_consulta.cnpj) {
        this.sumario.push({
          Nome: "CNPJ",
          Valor: mascaras.cnpj(sumario_consulta.cnpj),
        });
      }
      if (sumario_consulta.cep) {
        this.sumario.push({
          Nome: "CEP",
          Valor: mascaras.cep(sumario_consulta.cep),
        });
      }
    },
  },
};
</script>
<style lang="scss" src="@/styles/components/box_resultados.scss" scoped></style>

<style lang="scss" scoped>
.corpo {
  align-items: start;
  &__esq {
    justify-content: start;
    align-items: start;
    gap: 20px;
    height: 100%;
    .item {
      display: flex;
      align-items: center;
      gap: 15px;

      @include mediaQueryMax(small) {
        text-align: left;
        font-size: 0.8rem;
      }
    }
    .qnt-dias {
      font-size: 0.9rem;
      font-weight: 300;
    }
    .coeficiente {
      .key {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .sub-key {
          font-size: 0.9rem;
          line-height: 0.9rem;

          @include mediaQueryMax(small) {
            font-size: 0.7rem;
          }
        }
        @include mediaQueryMax(small) {
          font-size: 0.8rem;
        }
      }
      .dado {
        font-size: 2.5rem;

        @include mediaQueryMax(small) {
          font-size: 1rem;
        }
      }
    }
  }

  &__dir {
    padding-left: 10%;
    align-items: start;
    gap: 10px;

    @include mediaQueryMax(medium) {
      padding-left: 0;
    }

    .item {
      display: flex;
      gap: 10px;
      @include mediaQueryMax(small) {
        .key {
          width: 30ch;
        }
      }

      .icone {
        color: $mark;
        font-size: 1.8rem;
      }
    }

    @include mediaQueryMax(small) {
      .item {
        font-size: 0.8rem;
      }
    }
  }
}

.aviso-vazio {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
}
</style>
