import { render, staticRenderFns } from "./BOXATRPF003.vue?vue&type=template&id=81b67a08&scoped=true&"
import script from "./BOXATRPF003.vue?vue&type=script&lang=js&"
export * from "./BOXATRPF003.vue?vue&type=script&lang=js&"
import style0 from "@/styles/components/box_resultados.scss?vue&type=style&index=0&id=81b67a08&prod&lang=scss&scoped=true&"
import style1 from "./BOXATRPF003.vue?vue&type=style&index=1&id=81b67a08&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81b67a08",
  null
  
)

export default component.exports