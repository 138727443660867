<template>
  <div class="card">
    <div class="header">
      <div class="header__esq">
        <div class="titulo">
          Perfil de negócios - PF
          <span class="sumario" v-if="!this.resultado.erro && libera">
            <span>(</span>
            <span v-for="(param, index) in sumario" :key="param.Nome">
              {{ param.Nome }} : {{ param.Valor }}
              <span v-if="index != Object.keys(sumario).length - 1">,</span>
            </span>
            <span>)</span>
          </span>
        </div>
        <div class="subtitulo">
          Informações para análise da viabilidade e risco
        </div>
      </div>
      <div class="header__dir">
        <span class="material-icons icon">credit_card</span>
      </div>
    </div>
    <div
      v-if="!this.resultado.erro && this.resultado.atributos != null && libera"
      class="corpo"
    >
      <div class="corpo__esq">
        <Gauge
          :titulo="scoreCredito.especificacao"
          :valor="scoreCredito.valor"
          :rating="scoreCredito.rating"
          :classificacao="scoreCredito.classificacao"
        />
      </div>

      <div class="corpo__dir">
        <Gauge
          :titulo="scorePotencialDePagamento.especificacao"
          :valor="scorePotencialDePagamento.valor"
          :classificacao="scorePotencialDePagamento.classificacao"
          :rating="scorePotencialDePagamento.rating"
        />
      </div>

      <div class="corpo__bot">
        <div class="dados">
          <div class="limiteCred responsive">
            <span class="material-icons icons">credit_score</span>
            <p>
              {{ boxls.especificacao }}: R${{
                boxls.limite_sugerido.toLocaleString("pt-BR")
              }}
            </p>
          </div>
          <div class="geo responsive">
            <DoubleIcon
              lowerIcon="attach_money"
              biggerIcon="location_on"
            ></DoubleIcon>
            <div class="valoresRenda">
              <p>
                Geo Renda Média: R${{ media_reais.toLocaleString("pt-BR") }}
              </p>
              <p>
                Geo Renda Per-Capita: R${{
                  geoRendaperCapita.toLocaleString("pt-BR")
                }}
              </p>
              <p>
                Geo Renda Chefe de Família: R${{
                  geoRendaChefe.toLocaleString("pt-BR")
                }}
              </p>
            </div>
          </div>
          <div class="cpf responsive">
            <DoubleIcon lowerIcon="attach_money" biggerIcon="fingerprint">
            </DoubleIcon>
            <p class="valoresRendaCpf">
              Renda Presumida Nível do CPF: R${{
                rendaPresumidaCPF.toLocaleString("pt-BR")
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="resultado.atributos == null" class="aviso-vazio">
      Não existe perfil de negócio para o CPF informado
    </div>
    <div v-else-if="!libera">
      <Loading />
    </div>
    <div v-else class="corpo-nao-encontrado">
      Não existe perfil de negócio para o CPF informado
    </div>
  </div>
</template>

<script>
import { mascaras } from "@/helpers/mascaras";
import Gauge from "@/components/Visuais/Gauge";
import { consultasService } from "@/services";
import Loading from "@/components/Loading";
import DoubleIcon from "@/components/Visuais/Icons/DoubleIcon";

export default {
  name: "BOXATRPF003",
  props: {
    codigo_agrupador: String,
    campos: Object,
    contexto: Object,
  },
  components: {
    Gauge,
    Loading,
    DoubleIcon,
  },
  data() {
    return {
      scoreCredito: {
        especificacao: "",
        classificacao: "",
        rating: "",
        valor: 0,
      },

      scorePotencialDePagamento: {
        especificacao: "",
        classificacao: "",
        rating: "",
        valor: 0,
      },

      boxls: {
        especificacao: "",
        limite_sugerido: 0,
      },

      media_reais: 0,
      geoRendaperCapita: 0,
      geoRendaChefe: 0,
      rendaPresumidaCPF: 0,

      erro: false,
      sumario: [],
      resultado: {},
      libera: false,
    };
  },
  async mounted() {
    if (this.contexto.resultados.BOXATRPF003) {
      this.resultado = this.contexto.resultados.BOXATRPF003;
      this.libera = true;
      if (!this.contexto.resultados.BOXATRPF003.erro) {
        this.trataDados(this.contexto.resultados.BOXATRPF003);
      } else {
        this.erro = true;
      }
      this.encontraSumario(this.contexto.resultados.BOXATRPF003);
    } else {
      var objetoConsulta = {};
      this.contexto.inferido
        ? (objetoConsulta["cod_transacao_ciclica_pai"] =
            this.contexto.inferido["cod_transacao"])
        : "";
      this.campos["CPF"]
        ? (objetoConsulta["cpf_cnpj"] = this.campos["CPF"])
        : "";
      this.campos["DATA_REF"]
        ? (objetoConsulta["data_ref"] = this.campos["DATA_REF"])
        : "";
      this.campos["CEP"] ? (objetoConsulta["cep"] = this.campos["CEP"]) : "";
      objetoConsulta["cod_ref"] = "BOXATRPF003";
      objetoConsulta["tipo_pessoa"] = "pessoa_fisica";
      objetoConsulta["modo_resultado"] = "codigo";
      objetoConsulta["cod_agrupador"] = this.codigo_agrupador;
      await consultasService
        .realizaConsulta(objetoConsulta)
        .then((resultado) => {
          this.resultado = resultado;
          this.contexto.resultados["BOXATRPF003"] = { ...resultado };
        });
      this.libera = true;
      if (!this.resultado.erro) {
        this.trataDados(this.resultado);
      } else {
        this.erro = true;
      }
      this.encontraSumario(this.resultado);
    }
  },

  methods: {
    trataDados(resultado) {
      this.scoreCredito.especificacao =
        resultado.atributos.boxcsvar010_especificacao;
      this.scoreCredito.rating = resultado.atributos.boxcsvar010_rating;
      this.scoreCredito.valor = resultado.atributos.boxcsvar010_score;
      this.scoreCredito.classificacao =
        resultado.atributos.boxcsvar010_classificacao;

      this.boxls.especificacao = resultado.atributos.boxlsvar001_especificacao;
      this.boxls.limite_sugerido =
        resultado.atributos.boxlsvar001_limite_sugerido;

      this.scorePotencialDePagamento.especificacao =
        resultado.atributos.boxpp001_especificacao;
      this.scorePotencialDePagamento.classificacao =
        resultado.atributos.boxpp001_classificacao;
      this.scorePotencialDePagamento.rating =
        resultado.atributos.boxpp001_rating;
      this.scorePotencialDePagamento.valor = resultado.atributos.boxpp001_score;

      this.media_reais = resultado.atributos.bk_303_005_media_reais;
      this.geoRendaperCapita = resultado.atributos.bk_303_005_per_capita_reais;
      this.geoRendaChefe = resultado.atributos.bk_303_005_chefe_reais;
      this.rendaPresumidaCPF = resultado.atributos.boxpipf002_renda_presumida;
    },
    encontraSumario(resultado) {
      const sumario_consulta = resultado.sumario_consulta.parametros;
      if (sumario_consulta.cpf) {
        this.sumario.push({
          Nome: "CPF",
          Valor: mascaras.cpf(sumario_consulta.cpf),
        });
      }
      if (sumario_consulta.cep) {
        this.sumario.push({
          Nome: "CEP",
          Valor: mascaras.cep(sumario_consulta.cep),
        });
      }
    },
    mobile() {
      if (window.screen.width < 1024) {
        return true;
      }
      return false;
    },
  },
};
</script>
<style lang="scss" src="@/styles/components/box_resultados.scss" scoped></style>

<style lang="scss" scoped>
.icons {
  font-size: 3rem;
  grid-row: 1/3;
  margin-right: 0.4rem;
  text-align: left;

  @include mediaQuery(large) {
    font-size: 4rem;
  }

  @include mediaQueryMax(large) {
    font-size: 3vw;
  }

  @include mediaQueryMax(medium) {
    margin-right: 1.2rem;
    font-size: 2.5rem;
  }

  @include mediaQueryMax(small) {
    font-size: 2rem;
    margin-right: 0.8rem;
  }
}

.corpo__bot {
  display: flex !important;
  justify-content: center;

  .dados {
    height: 100%;
    padding-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    @include mediaQuery(medium) {
      width: 100%;
      font-size: 1vw;
    }

    @include mediaQuery(large) {
      font-size: 1vw;
      width: 100%;
    }

    @include mediaQueryMax(medium) {
      flex-direction: column;
      font-size: 1rem;
    }

    @include mediaQueryMax(small) {
      flex-direction: column;
      font-size: 0.75rem;
    }

    .responsive {
      margin-bottom: 1.3rem;
    }

    .geo {
      @include flex-center(row);
      .valoresRenda {
        @include mediaQueryMax(large) {
          margin-left: 0.7rem;
        }
        @include mediaQueryMax(medium) {
          margin-left: 2rem;
        }
        @include mediaQueryMax(small) {
          margin-left: 3.2rem;
        }
      }
    }

    .cpf {
      @include flex-center(row);
      .valoresRendaCpf {
        @include mediaQueryMax(large) {
          margin-left: 0.7rem;
        }
        @include mediaQueryMax(medium) {
          margin-left: 2rem;
        }
        @include mediaQueryMax(small) {
          margin-left: 3.2rem;
        }
      }
    }
    .limiteCred {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}

.aviso-vazio {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
}
</style>
