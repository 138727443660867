<template>
  <div class="card">
    <div class="header">
      <div class="header__esq">
        <div class="titulo">
          Identifica Pessoa Física
          <span class="sumario" v-if="!this.resultado.erro && libera">
            <span>(</span>
            <span v-for="(param, index) in sumario" :key="param.Nome">
              {{ param.Nome }} : {{ param.Valor }}
              <span v-if="index != Object.keys(sumario).length - 1">,</span>
            </span>
            <span>)</span>
          </span>
        </div>
        <div class="subtitulo">Informações básicas de pessoa física</div>
      </div>
      <div class="header__dir">
        <span class="codigo-agrupador">{{ codigo_agrupador }}</span>
        <div class="data" v-if="!this.resultado.erro && libera">
          <span
            >{{
              contexto.resultados.BOXATRPF001.sumario_consulta.data_transacao_brt
                .slice(0, 10)
                .split("-")
                .reverse()
                .join("/")
            }}
          </span>
          <span>
            {{
              contexto.resultados.BOXATRPF001.sumario_consulta.data_transacao_brt.slice(
                11,
                19
              )
            }}</span
          >
        </div>
        <span class="material-icons icon">fingerprint</span>
      </div>
    </div>
    <!-- Corpo Resultado OK -->
    <div
      v-if="!this.resultado.erro && this.resultado.atributos != null && libera"
      class="corpo"
    >
      <div class="corpo__esq">
        <div>
          Nome: <span class="dado">{{ nome }}</span>
        </div>
        <div>
          Idade: <span class="dado">{{ idade }} anos</span>
        </div>
        <div>
          Ano Óbito:
          <span class="dado">{{ anoObito ? anoObito : "Sem informação" }}</span>
        </div>
      </div>
      <div class="corpo__dir">
        <div>
          Data de Nascimento: <span class="dado">{{ dataNascimento }}</span>
        </div>
        <div class="genero" v-if="generoInferido == 'M'">
          Gênero Inferido:
          <span class="dado"> Masculino </span>
          <span class="material-icons">male</span>
        </div>
        <div class="genero" v-else-if="generoInferido == 'F'">
          Gênero Inferido:
          <span class="dado"> Feminino </span>
          <span class="material-icons">female</span>
        </div>
        <div class="genero" v-else>
          Gênero:
          <span class="dado"> Indefinido </span>
        </div>
      </div>
    </div>
    <div v-else-if="resultado.atributos == null" class="aviso-vazio">
      Nenhum resultado encontrado
    </div>
    <!-- Corpo carregando resultado -->
    <div v-else-if="!libera">
      <Loading />
    </div>

    <!-- Corpo resultado não encontrado -->
    <div v-else-if="resultado.erro != null" class="corpo-nao-encontrado">
      {{ resultado.erro.descricao }}
    </div>

    <div class="corpo-inferior">
      <div
        class="alertas"
        :class="{ 'span-2': historico || produtosNaoConsultados.length == 0 }"
      >
        <div class="titulo">
          Alertas:
          <span class="material-icons"> error_outline </span>
        </div>

        <div class="line" />
        <div
          class="corpo-alertas"
          v-if="!loading_alertas && alertas.length != 0"
        >
          <div
            class="alerta-basic"
            :class="{ alerta: alerta.Recomendado && !historico }"
            v-for="alerta in alertas"
            :key="alerta.Nome"
            @click="consultaAlerta(alerta)"
          >
            <div class="bolinha">
              <div class="bolinha" :class="decideCorIndicador(alerta)"></div>
            </div>
            {{ alerta.Nome }}
            <span
              class="material-icons icon-next-consulta"
              v-if="alerta.Recomendado && !historico && permitido(alerta)"
              :class="decideCorIndicador(alerta)"
              >near_me</span
            >
          </div>
        </div>
        <div class="corpo-alertas-loading" v-else-if="alertas.length == 0">
          Não encontramos alertas para o CPF informado
        </div>
        <div class="corpo-alertas-loading" v-else>
          <Loading />
        </div>
      </div>
      <div
        class="extras"
        v-if="!historico && produtosNaoConsultados.length != 0"
      >
        <div class="titulo">Consultas Disponíveis:</div>
        <div class="line" />
        <div
          class="produtos"
          v-for="produto in produtosNaoConsultados"
          :key="produto"
          @click="addExtra(produto)"
        >
          <div class="bolinha">
            <div class="bolinha --extra"></div>
          </div>
          {{ listaDeProdutos.PF[produto] }}
          <span class="material-icons icon-next-consulta --extra-icon"
            >near_me</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formataData, formataNome } from "@/helpers/utilitarios";
import { mascaras } from "@/helpers/mascaras";
import { consultasService } from "@/services";
import Loading from "@/components/Loading";
import alertasJSON from "@/helpers/alertasPF.json";
import { alertasHelper } from "@/helpers/alertas.js";
import listaProdutos from "@/helpers/lista_produtos.json";
import { produtosStore } from "@/stores/produtos";
import { mapActions, mapState } from "pinia";
export default {
  name: "BOXATRPF001",
  components: {
    Loading,
  },
  props: {
    codigo_agrupador: String,
    campos: Object,
    contexto: Object,
    historico: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataNascimento: "",
      nome: "",
      anoObito: "",
      generoInferido: "",
      idade: 0,
      sumario: [],
      erro: false,
      resultado: {},
      libera: false,
      loading_alertas: true,
      loading_extras: true,
      alertas: [],
      lista_produtos: listaProdutos,
    };
  },
  async mounted() {
    if (this.contexto.resultados.BOXATRPF001) {
      this.resultado = this.contexto.resultados.BOXATRPF001;
      this.libera = true;
      if (!this.contexto.resultados.BOXATRPF001.erro) {
        this.trataDados(this.contexto.resultados.BOXATRPF001);
      } else {
        this.erro = true;
      }
      this.encontraSumario(this.contexto.resultados.BOXATRPF001);
    } else {
      var objetoConsulta = {};
      
      this.contexto.inferido
        ? (objetoConsulta["cod_transacao_ciclica_pai"] =
            this.contexto.inferido["cod_transacao"])
        : "";
      this.campos["CPF"]
        ? (objetoConsulta["cpf_cnpj"] = this.campos["CPF"])
        : "";
      this.campos["CEP"] ? (objetoConsulta["cep"] = this.campos["CEP"]) : "";
      this.campos["DATA_REF"]
        ? (objetoConsulta["data_ref"] = this.campos["DATA_REF"])
        : "";
      objetoConsulta["cod_ref"] = "BOXATRPF001";
      objetoConsulta["tipo_pessoa"] = "pessoa_fisica";
      objetoConsulta["modo_resultado"] = "codigo";
      objetoConsulta["cod_agrupador"] = this.codigo_agrupador;
      await consultasService
        .realizaConsulta(objetoConsulta)
        .then((resultado) => {
          this.resultado = resultado;
          this.contexto.resultados["BOXATRPF001"] = { ...resultado };
        });
      this.libera = true;
      if (!this.resultado.erro) {
        this.trataDados(this.resultado);
      } else {
        this.erro = true;
      }
      this.encontraSumario(this.resultado);
    }

    // Faz consulta dos alertas
    this.loading_alertas = true;

    if (this.contexto.resultados.BOXWRNPF001) {
      this.alertas = alertasHelper.trataAlertas(
        this.contexto.resultados.BOXWRNPF001,
        alertasJSON
      );
      this.loading_alertas = false;
    } else {
      const response = await alertasHelper.consultaAlertas(
        "BOXWRNPF001",
        this.codigo_agrupador,
        this.campos
      );
      this.alertas = alertasHelper.trataAlertas(response, alertasJSON);
      this.contexto.resultados.BOXWRNPF001 = response;
      this.loading_alertas = false;
    }

    this.loading_extras = true;
  },
  methods: {
    permitido(alerta) {
      if (alerta.Recomendado) {
        var permitido = false;
        this.produtos.forEach((produto) => {
          if (alerta.Recomendado == produto.Box) {
            permitido = true;
          }
        });
      }

      return permitido;
    },
    ...mapActions(produtosStore, ["produtosAusentes"]),
    decideCorIndicador(alerta) {
      switch (alerta.Indicador) {
        case "Positivo":
          return "--positive";
        case "Negativo":
          return "--negative";
        case "Atencao":
          return "--warning";
      }
      return "";
    },

    async consultaAlerta(alerta) {
      if (!alerta.Recomendado || this.historico || !this.permitido(alerta)) {
        return;
      }
      if (await alertasHelper.executaAlerta(alerta, this.contexto)) {
        this.$emit("addRequisitado", alerta.Recomendado, this.contexto.id);
      }
    },

    trataDados(resultado) {
      this.dataNascimento = formataData(
        resultado.atributos.bk_101_001_data_nascimento
      );
      this.nome = formataNome(resultado.atributos.bk_101_001_nome);
      this.anoObito = resultado.atributos.bk_101_003_ano_obito;
      this.generoInferido = resultado.atributos.bk_101_004_genero_inferido;

      if (this.dataNascimento) {
        const dataNascimento = this.dataNascimento
          .split("/")
          .reverse()
          .join("-");
        this.idade = this.$moment().diff(dataNascimento, "years", false);
      }
    },
    encontraSumario(resultado) {
      const sumario_consulta = resultado.sumario_consulta.parametros;
      if (sumario_consulta.cpf) {
        this.sumario.push({
          Nome: "CPF",
          Valor: mascaras.cpf(sumario_consulta.cpf),
        });
      }
      if (sumario_consulta.cep) {
        this.sumario.push({
          Nome: "CEP",
          Valor: mascaras.cep(sumario_consulta.cep),
        });
      }
    },
    async addExtra(produto) {
      if (await alertasHelper.executaExtra(listaProdutos.PF[produto], 1)) {
        this.$emit("addRequisitado", produto, this.contexto.id);
      }
    },
  },
  computed: {
    ...mapState(produtosStore, {
      produtos: "produtos",
    }),
    listaDeProdutos() {
      return listaProdutos;
    },
    produtosNaoConsultados() {
      return this.produtosAusentes(this.contexto.requisitados, "PF");
    },
  },
};
</script>
<style lang="scss" src="@/styles/components/box_cabecalho.scss" scoped>
.aviso-vazio {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
}
</style>

<style lang="scss" scoped></style>
