<template>
  <div class="card">
    <div class="header">
      <div class="header__esq">
        <div class="titulo">
          Relações Societárias - PF
          <span class="sumario" v-if="!this.resultado.erro && libera">
            <span>(</span>
            <span v-for="(param, index) in sumario" :key="param.Nome">
              {{ param.Nome }} : {{ param.Valor }}
              <span v-if="index != Object.keys(sumario).length - 1">,</span>
            </span>
            <span>)</span>
          </span>
        </div>
        <div class="subtitulo">Informações de sociedade da pessoa física</div>
      </div>
      <div class="header__dir">
        <span class="material-icons icon">group</span>
      </div>
    </div>
    <div
      v-if="
        !this.resultado.erro &&
        this.resultado.atributos != null &&
        libera &&
        this.resultado.registro_encontrado
      "
      class="corpo"
    >
      <div class="corpo__esq">
        <div class="sociedade-info">
          <div class="sociedade-info__atividade">
            Possui sociedade ativa
            <span class="material-icons check">check_circle</span>
          </div>
          <p>
            Início da atividade atual:
            {{ new Date().getFullYear() - avg_tempo_anos_data_ref_ativa }},
            {{ avg_tempo_anos_data_ref_ativa }} anos
          </p>
        </div>
        <div class="sociedade-valor">
          <div>
            Quantidade de CNPJ associados ao CPF : {{ qtde_cnpj_distintos }}
          </div>
          <div class="sociedade-valor__capital">
            <div class="sociedade-valor__capital__titulo">
              <span class="material-icons-outlined money">paid</span>
              Capital Social Médio:
            </div>
            <span class="sociedade-valor__quantia"
              >R$ {{ avg_capital_social_ativa.toLocaleString("pt-BR") }}</span
            >
          </div>
        </div>
      </div>
      <div class="corpo__dir">
        <div class="datas">
          <div>Início da atividade ativa associada ao CPF</div>
          <div class="data-dupla" v-if="min_data_inicio != max_data_inicio">
            <div>Data mais antiga</div>
            <div>Data mais recente</div>
            <div>{{ min_data_inicio }}</div>
            <div>{{ max_data_inicio }}</div>
          </div>
          <div class="data-simples" v-else>
            <div>{{ max_data_inicio }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="resultado.atributos == null" class="aviso-vazio">
      Não existe relação societária para o CPF informado.
    </div>
    <div v-else-if="!libera">
      <Loading />
    </div>
    <div v-else class="corpo-nao-encontrado">
      Não existe relação societária para o CPF informado.
    </div>
  </div>
</template>

<script>
import { formataData, formataValor } from "@/helpers/utilitarios";
import { mascaras } from "@/helpers/mascaras";
import { consultasService } from "@/services";
import Loading from "@/components/Loading";

export default {
  name: "BOXATRPF004",
  components: {
    Loading,
  },
  props: {
    codigo_agrupador: String,
    campos: Object,
    contexto: Object,
  },
  data() {
    return {
      avg_capital_social_ativa: 0,
      avg_tempo_anos_data_ref_ativa: 0,
      ind_sociedade_com_cnpj_ativo: false,
      max_data_inicio: "",
      min_data_inicio: "",
      qtde_cnpj_distintos: 0,
      erro: false,
      sumario: [],
      libera: false,
      resultado: {},
    };
  },
  async mounted() {
    if (this.contexto.resultados.BOXATRPF004) {
      this.resultado = this.contexto.resultados.BOXATRPF004;
      this.libera = true;
      if (!this.contexto.resultados.BOXATRPF004.erro) {
        this.trataDados(this.contexto.resultados.BOXATRPF004);
      } else {
        this.erro = true;
      }
      this.encontraSumario(this.contexto.resultados.BOXATRPF004);
    } else {
      var objetoConsulta = {};
      this.contexto.inferido
        ? (objetoConsulta["cod_transacao_ciclica_pai"] =
            this.contexto.inferido["cod_transacao"])
        : "";
      this.campos["CPF"]
        ? (objetoConsulta["cpf_cnpj"] = this.campos["CPF"])
        : "";
      this.campos["DATA_REF"]
        ? (objetoConsulta["data_ref"] = this.campos["DATA_REF"])
        : "";
      this.campos["CEP"] ? (objetoConsulta["cep"] = this.campos["CEP"]) : "";
      objetoConsulta["cod_ref"] = "BOXATRPF004";
      objetoConsulta["tipo_pessoa"] = "pessoa_fisica";
      objetoConsulta["modo_resultado"] = "codigo";
      objetoConsulta["cod_agrupador"] = this.codigo_agrupador;
      await consultasService
        .realizaConsulta(objetoConsulta)
        .then((resultado) => {
          this.resultado = resultado;
          this.contexto.resultados["BOXATRPF004"] = { ...resultado };
        });
      this.libera = true;
      if (!this.resultado.erro) {
        this.trataDados(this.resultado);
      } else {
        this.erro = true;
      }
      this.encontraSumario(this.resultado);
    }
  },
  methods: {
    trataDados(resultado) {
      if (this.resultado.registro_encontrado) {
        this.avg_capital_social_ativa =
          resultado.atributos.bk_103_002_avg_capital_social_ativa;
        this.avg_tempo_anos_data_ref_ativa =
          resultado.atributos.bk_103_002_avg_tempo_anos_data_ref_ativa;
        this.ind_sociedade_com_cnpj_ativo =
          resultado.atributos.bk_103_002_ind_sociedade_com_cnpj_ativo;
        this.max_data_inicio = formataData(
          resultado.atributos.bk_103_002_max_data_inicio_atividade_ativa
        );
        this.min_data_inicio = formataData(
          resultado.atributos.bk_103_002_min_data_inicio_atividade_ativa
        );
        this.qtde_cnpj_distintos =
          resultado.atributos.bk_103_002_qtde_cnpj_distintos_ativa;
      }
    },
    encontraSumario(resultado) {
      const sumario_consulta = this.resultado.sumario_consulta.parametros;
      if (sumario_consulta.cpf) {
        this.sumario.push({
          Nome: "CPF",
          Valor: mascaras.cpf(sumario_consulta.cpf),
        });
      }
      if (sumario_consulta.cep) {
        this.sumario.push({
          Nome: "CEP",
          Valor: mascaras.cep(sumario_consulta.cep),
        });
      }
    },
  },
};
</script>
<style lang="scss" src="@/styles/components/box_resultados.scss" scoped></style>

<style lang="scss" scoped>
.corpo {
  display: grid;
  grid-template-columns: 1fr;

  @include mediaQuery(medium) {
    grid-template-columns: 1fr 1fr;
  }

  &__esq {
    gap: 10px;
  }
}

.sociedade-info {
  @include flex-column();
  gap: 10px;
  font-size: 1.1rem;
  &__atividade {
    @include flex-align-line();
    justify-content: center;
    .check {
      color: $positive-green;
    }
  }
}

.sociedade-valor {
  @include flex-column();
  gap: 10px;
  font-size: 1.1rem;

  &__capital {
    align-items: center;
    line-height: 2rem;
    gap: 5px;
    transform: translateX(-5px);
    @include flex-center(column);

    @include mediaQuery(medium) {
      flex-direction: row;
    }

    &__titulo {
      @include flex-align-line();
      gap: 10px;
    }
  }
  &__quantia {
    font-size: 1.5rem;

    @include mediaQuery(medium) {
      font-size: 1.2rem;
    }
  }
  .money {
    color: $positive-green;
    font-size: 2rem;
  }
}

.datas {
  @include flex-center(column);
  gap: 15px;
}

.data-dupla {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
}

.data-simples {
  text-align: center;
}

.aviso-vazio {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
}
</style>
