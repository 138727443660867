<template>
  <div class="esqueleto" ref="content" v-if="camposCheck">
    <div class="grid">
      <div class="contexto" v-for="contexto in contexto" :key="contexto.id">
        <div class="separator" v-if="contexto.id != 0">
          Consultas cíclica com os dados:

          <span
            class="dado_inferido"
            v-for="campo in Object.keys(contexto.campos)"
            :key="campo"
          >
            <span v-if="['cep', 'cpf', 'cnpj'].includes(campo)"></span>
            {{ campo.toUpperCase() }}
            :
            {{ contexto.campos[campo] }}
          </span>
        </div>
        <div
          v-for="obrigatorio in Object.keys(contexto.obrigatorio)"
          :key="obrigatorio"
          :id="obrigatorio + contexto.id"
        >
          <component
            :is="obrigatorio"
            :campos="contexto.campos"
            :codigo_agrupador="codigo_agrupador"
            :contexto="contexto"
            @addConsulta="comecaNovaConsulta"
            :quantidadeDeContextos="quantidadeDeContextos"
            @addRequisitado="addRequisitado"
          />
        </div>
        <div
          v-for="requisitado in Object.keys(contexto.requisitados)"
          :key="requisitado"
          :id="requisitado + contexto.id"
        >
          <component
            :is="requisitado"
            :codigo_agrupador="codigo_agrupador"
            :campos="contexto.campos"
            :contexto="contexto"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { produtosStore } from "@/stores/produtos";
import { mapActions, mapWritableState } from "pinia";

import BOXATRPF001 from "@/components/Outputs/BOXATRPF001";
import BOXATRPF002 from "@/components/Outputs/BOXATRPF002";
import BOXATRPF003 from "@/components/Outputs/BOXATRPF003";
import BOXATRPF004 from "@/components/Outputs/BOXATRPF004";
import BOXATRPF005 from "@/components/Outputs/BOXATRPF005";
import BOXATRPF006 from "@/components/Outputs/BOXATRPF006";
import BOXATRDAY001 from "@/components/Outputs/BOXATRDAY001";

import BOXATRPJ001 from "@/components/Outputs/BOXATRPJ001";
import BOXATRPJ002 from "@/components/Outputs/BOXATRPJ002";
import BOXATRPJ003 from "@/components/Outputs/BOXATRPJ003";
import BOXATRPJ004 from "@/components/Outputs/BOXATRPJ004";

import BOXATRGEO001 from "@/components/Outputs/BOXATRGEO001";
import BOXATRGEO002 from "@/components/Outputs/BOXATRGEO002";

import BOXATRAUTO001 from "@/components/Outputs/BOXATRAUTO001";
import BOXATRAUTO002 from "@/components/Outputs/BOXATRAUTO002";

import Loading from "@/components/Loading";
import Aos from "aos";
import "aos/dist/aos.css";

import html2pdf from "html2pdf.js";

export default {
  name: "Resultados",
  components: {
    Loading,

    BOXATRPJ001,
    BOXATRPJ002,
    BOXATRPJ003,
    BOXATRPJ004,

    BOXATRPF001,
    BOXATRPF002,
    BOXATRPF003,
    BOXATRPF004,
    BOXATRPF005,
    BOXATRPF006,
    BOXATRDAY001,

    BOXATRGEO001,
    BOXATRGEO002,

    BOXATRAUTO001,
    BOXATRAUTO002,
  },
  props: {
    produtos: Array,
    campos: Array,
    requisitados: Object,
    obrigatorio: String,
    contexto: Array,
    inferido: Object,
  },
  data() {
    return {
      showModal: false,
      camposTratados: {},
      camposCheck: false,
    };
  },
  created() {
    Aos.init({ duration: 1500, once: true });

    for (let i = 0; i < this.campos.length; i++) {
      if (this.campos[i].Valor) {
        this.camposTratados[this.campos[i].Nome] = this.campos[i].Valor;
      }
    }
    if (Object.keys(this.camposTratados).length == 0) {
      this.$router.push("/consultas/box/0");
    }
    this.contexto[this.contexto.length - 1].campos = this.camposTratados;
    this.camposCheck = true;
  },
  methods: {
    addRequisitado(recomendado, id) {
      this.$emit("addRequisitado", recomendado, id);
    },

    comecaNovaConsulta(obj) {
      this.$emit("addConsulta", obj);
    },
  },
  computed: {
    ...mapWritableState(produtosStore, {
      codigo_agrupador: "codigo_agrupador",
    }),

    quantidadeDeContextos() {
      return this.contexto.length;
    },
  },
};
</script>

<style scoped>
.esqueleto {
  display: flex;
  flex-direction: column;
  width: 95%;
}
.line {
  margin-top: 20px;
  border-bottom: 1px solid black;
  font-weight: 500;
  font-style: italic;
}
.grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}
.contexto {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.separator {
  display: flex;
  gap: 10px;
  font-weight: 600;
  border-bottom: 1px solid black;
  font-size: 1.2rem;
}

.dado_inferido {
  color: var(--mark);
}

@media (min-width: 1024px) {
  .grid {
    grid-template-columns: 1fr;
    gap: 30px;
  }
}

@media (min-width: 1400px) {
  .esqueleto {
    display: flex;
    justify-content: center;
  }
}
</style>
