<template>
  <div>
    <div class="grid" v-if="resultados">
        <div class="back">
          <HeaderResultado :codigoAgrupador="codigo_agrupador" :historico="true" /> 
        </div>
        <div class="contexto" v-for="contexto in contextos" :key="contexto.id">
          
          <div class="separator" v-if="contexto.id != 0">
            Consultas cíclica com os dados:
           
            <span class="dado_inferido" v-for="campo in Object.keys(contexto.campos)" :key="campo">
              <span v-if="['cep','cpf','cnpj'].includes(campo)"></span>
              {{ campo.toUpperCase() }}
              :
              {{
                contexto.campos[campo]
              }}
            </span> 
            
          </div>
          
          <div v-for="obrigatorio in Object.keys(contexto.obrigatorio)" :key="obrigatorio">
            <component
              :is="obrigatorio"
              :campos="contexto.campos"
              :codigo_agrupador="codigo_agrupador"
              :contexto="contexto"
              @gerapdf="gerapdf"
              :historico="true"
            />
          </div>
          <div v-for="requisitado in Object.keys(contexto.requisitados)" :key="requisitado">
            <component
              v-if="!requisitado.startsWith('BOXWRN')"
              :is="requisitado"
              :codigo_agrupador="codigo_agrupador"
              :campos="contexto.campos"
              :contexto="contexto"
              :historico="true"

            />
          </div>
          
         
        </div>        
      </div>
  </div>
</template>

<script>
import BOXATRPF001 from "@/components/Outputs/BOXATRPF001";
import BOXATRPF003 from "@/components/Outputs/BOXATRPF003";
import BOXATRPF004 from "@/components/Outputs/BOXATRPF004";
import BOXATRPF005 from "@/components/Outputs/BOXATRPF005";
import BOXATRPF006 from "@/components/Outputs/BOXATRPF006";
import BOXATRPF002 from "@/components/Outputs/BOXATRPF002";

import BOXATRPJ001 from "@/components/Outputs/BOXATRPJ001";
import BOXATRPJ002 from "@/components/Outputs/BOXATRPJ002";
import BOXATRPJ003 from "@/components/Outputs/BOXATRPJ003";
import BOXATRPJ004 from "@/components/Outputs/BOXATRPJ004";

import BOXATRGEO001 from "@/components/Outputs/BOXATRGEO001";
import BOXATRGEO002 from "@/components/Outputs/BOXATRGEO002";

import HeaderResultado from "@/components/Visuais/HeaderResultado"

import html2pdf from "html2pdf.js";

export default {
  name: "ResultadoHistorico",
  components: {
    BOXATRPJ001,
    BOXATRPJ002,
    BOXATRPJ003,
    BOXATRPJ004,

    BOXATRPF001,
    BOXATRPF002,
    BOXATRPF003,
    BOXATRPF004,
    BOXATRPF005,
    BOXATRPF006,

    BOXATRGEO001,
    BOXATRGEO002,

    HeaderResultado
  },
  props: {
    resultados: Object,
  },
  data() {
    return {
      consultas: [],
      contextosCriados: [], // contextosCriados -> usados para definir quais consultas estão em cada contexto.
      contextos: [], // contextos -> usado para renderização, {id, realizadas, obrigatorias}
      consultasRealizadas: {},
      consultasObrigatorias: {},
      codigo_agrupador: ''
    };
  },
  methods: {
    createFirstContext() {
      var resultados = {}
      var campos = {}
      
      this.resultados.Consultas.forEach((consulta) => {
        this.consultas.push(consulta)
        if (!consulta.CodigoTransacaoCiclicaPai) {
          this.consultas.pop(consulta)
          var box = consulta.Consulta.sumario_consulta.parametros.cod_ref;
          if (box == "BOXATRPF001" || box == "BOXATRPJ001" || box == "BOXATRGEO001") {
            this.$set(this.consultasObrigatorias, box, true);
            resultados[box] = consulta.Consulta
            campos = this.retornaCampos(consulta)
          } else {
            this.$set(this.consultasRealizadas, box, true);
            resultados[box] = consulta.Consulta
          }
        }
        else {
          this.externalizaParametros(consulta, campos);
        }
        
      });
      this.$set(this.contextos, 0, {
        id: 0,
        obrigatorio: this.consultasObrigatorias,
        requisitados: this.consultasRealizadas,
        resultados: resultados,
        campos:campos
      });
      
    },

    defineContextos() {
      var contexts = []
      this.consultas.forEach(consulta => {
        var equal = false
        contexts.forEach((context) => {
          if(this.shallowEqual(context,consulta.Parametros)){
            equal = true
          }
        })
        
        if(!equal){
          contexts.push(consulta.Parametros)
          this.criaContexto(consulta.Parametros)
          this.insereNoContexto(consulta)
        }
        else {
          this.insereNoContexto(consulta)
        }
      })
    },
    retornaCampos(consulta){
      var campos = {}
      var parametrosUteis = ['cpf', 'cnpj', 'cep']
      var sumario = consulta.Consulta.sumario_consulta.parametros
      Object.keys(sumario).forEach(parametro => {
        if(parametrosUteis.includes(parametro) && sumario[parametro]){
          campos[parametro.toUpperCase()] = sumario[parametro]
        }
      })
      return campos
    },
    insereNoContexto(consulta) {
      var newCampos  = {}
      Object.keys(consulta.Parametros).forEach(campo => {
        newCampos[campo.toUpperCase()] = consulta.Parametros[campo]
      })

      var box = consulta.Consulta.sumario_consulta.parametros.cod_ref
      this.contextos.forEach(contexto => {
        var x = {...contexto.campos}
        
        if(this.shallowEqual(x,newCampos)) {
          if(box == "BOXATRPF001" || box == "BOXATRPJ001" || box == "BOXATRGEO001") {
            contexto.obrigatorio[box] = true
          }
          else {
            contexto.requisitados[box] = true
          }
          contexto.resultados[box] = {...consulta.Consulta}
        }
      })


    },
    criaContexto(campos) {
      var newCampos  = {}
      Object.keys(campos).forEach(campo => {
        newCampos[campo.toUpperCase()] = campos[campo]
      })

      var newContext = {
        id: this.contextos.length,
        obrigatorio: {},
        requisitados: {},
        resultados: {},
        campos: newCampos
      }
      this.$set(this.contextos, newContext.id, newContext);
    },
    shallowEqual(object1, object2) {
      const keys1 = Object.keys(object1);
      const keys2 = Object.keys(object2);
      if (keys1.length !== keys2.length) {
        return false;
      }
      for (let key of keys1) {
        if (object1[key] !== object2[key]) {
          return false;
        }
      }
      return true;
    },
    externalizaParametros(consulta){
      consulta.Parametros = {};
      var parametrosUteis = ['cpf', 'cnpj', 'cep']
      var sumario = consulta.Consulta.sumario_consulta.parametros
      Object.keys(sumario).forEach(parametro => {
        if(parametrosUteis.includes(parametro) && sumario[parametro]){
          this.$set(consulta.Parametros, parametro, sumario[parametro])
        }
      })
    },
    async gerapdf() {
      this.analytics(
        "Impressão PDF",
        "Utilitários",
        "O usuário gerou um pdf dos resultados obtidos"
      );

      var worker = html2pdf();
      var opt = {
        margin: 0.3,
        filename: `${this.codigo_agrupador}.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: {
          scale: 2.6,
          allowTaint: true,
          useCors: true,
        },
        pagebreak: { mode: ["avoid-all", "css", "legacy"] },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
        pagebreak: { mode: "avoid-all" },
      };
      // var items = document.querySelector(".esqueleto");

      var cards = document.getElementsByClassName("card");

      var elementToPrint = document.createElement("div");
      elementToPrint.classList.add("html2pdf__page-break");

      var pulaLinha = document.createElement("br");
      cards.forEach((box) => {
        const boxClone = box.cloneNode(true);
        boxClone.classList.add("html2pdf__page-break");
        elementToPrint.appendChild(boxClone.cloneNode(true));
        elementToPrint.appendChild(pulaLinha.cloneNode(true));
      });


      await worker.set(opt).from(elementToPrint).toPdf().save();
    },
  },
  mounted() {
    this.codigo_agrupador = this.resultados.CodigoAgrupador
    this.createFirstContext();
    this.defineContextos();

  },
};
</script>

<style scoped>

.esqueleto {
  display: flex;
  flex-direction: column;
  margin-top:-30px;
  width: 95%;
  /* justify-content:center; */
}


.icon {
  transition: 0.2s ease all;

}


.line {
  margin-top: 20px;
  border-bottom: 1px solid black;
  font-weight: 500;
  font-style: italic;
}
.grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}
.contexto{
  display:flex;
  flex-direction: column;
  gap:30px;
}

.separator {
  display: flex;
  gap: 10px;
  font-weight: 600;
  border-bottom: 1px solid black;
  font-size: 1.2rem;
}

.dado_inferido {
  color: var(--mark);
}

@media (min-width: 1024px) {
  .grid {
    grid-template-columns: 1fr;
    gap: 30px;
  }
}

@media (min-width: 1400px) {
  .esqueleto {
    display: flex;
    justify-content: center;
  }
}
</style>
